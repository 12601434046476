import React from "react";
import Undermaintenance from "../../Common/Undermaintenance";
export default function HashDice() {
  return (
    <Undermaintenance
      type="soon"
      time=""
      support={false}
      p1="For our users to experience wonderful playtime,"
      p2="we are about to release new games. Stay tuned!"
    />
  );
}
