import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { modalsControl } from "../../Contexts/ModalsControl";
import { useTranslation, Trans } from "react-i18next";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  // border: "2px solid #000",
  boxShadow: 24,
};

export default function SignupSpin() {
  const [open, setOpen] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { setSignUpOpen } = useContext(modalsControl);
  const prizes = [
    {
      id: 28,
      created_at: "2023-02-03T15:22:47.896Z",
      updated_at: "2023-02-03T15:22:47.896Z",
      wheel_id: 2083,
      network_id: "solana",
      amount: 0.01,
      locked: false,
      probability: 0,
      wheel_position: 1080,
    },
    {
      id: 18,
      created_at: "2023-02-03T15:22:47.784Z",
      updated_at: "2023-02-03T15:22:47.784Z",
      wheel_id: 2083,
      network_id: "bcw",
      amount: 50,
      locked: true,
      probability: 0,
      wheel_position: 1058,
    },
    {
      id: 20,
      created_at: "2023-02-03T15:22:47.803Z",
      updated_at: "2023-02-03T15:22:47.803Z",
      wheel_id: 2083,
      network_id: "bcw",
      amount: 1,
      locked: true,
      probability: 0,
      wheel_position: 1037,
    },
    {
      id: 21,
      created_at: "2023-02-03T15:22:47.813Z",
      updated_at: "2023-02-03T15:22:47.813Z",
      wheel_id: 2083,
      network_id: "bcw",
      amount: 0.01,
      locked: true,
      probability: 0,
      wheel_position: 1013,
    },
    {
      id: 23,
      created_at: "2023-02-03T15:22:47.842Z",
      updated_at: "2023-02-03T15:22:47.842Z",
      wheel_id: 2083,
      network_id: "tron",
      amount: 2,
      locked: false,
      probability: 0,
      wheel_position: 991,
    },
    {
      id: 27,
      created_at: "2023-02-03T15:22:47.884Z",
      updated_at: "2023-02-03T15:22:47.884Z",
      wheel_id: 2083,
      network_id: "solana",
      amount: 0.1,
      locked: false,
      probability: 0,
      wheel_position: 968,
    },
    {
      id: 31,
      created_at: "2023-02-03T15:22:47.926Z",
      updated_at: "2023-02-03T15:22:47.926Z",
      wheel_id: 2083,
      network_id: "usdt",
      amount: 0.1,
      locked: false,
      probability: 0,
      wheel_position: 945,
    },
    {
      id: 32,
      created_at: "2023-02-03T15:22:47.935Z",
      updated_at: "2023-02-03T15:22:47.935Z",
      wheel_id: 2083,
      network_id: "usdt",
      amount: 1,
      locked: false,
      probability: 0,
      wheel_position: 922,
    },
    {
      id: 24,
      created_at: "2023-02-03T15:22:47.851Z",
      updated_at: "2023-02-03T15:22:47.851Z",
      wheel_id: 2083,
      network_id: "tron",
      amount: 0.5,
      locked: false,
      probability: 0,
      wheel_position: 899,
    },
    {
      id: 30,
      created_at: "2023-02-03T15:22:47.916Z",
      updated_at: "2023-02-03T15:22:47.916Z",
      wheel_id: 2083,
      network_id: "polygon",
      amount: 0.1,
      locked: false,
      probability: 0,
      wheel_position: 878,
    },
    {
      id: 19,
      created_at: "2023-02-03T15:22:47.793Z",
      updated_at: "2023-02-03T15:22:47.793Z",
      wheel_id: 2083,
      network_id: "bcw",
      amount: 10,
      locked: true,
      probability: 0,
      wheel_position: 855,
    },
    {
      id: 25,
      created_at: "2023-02-03T15:22:47.860Z",
      updated_at: "2023-02-03T15:22:47.860Z",
      wheel_id: 2083,
      network_id: "ethereum",
      amount: 0.5,
      locked: false,
      probability: 0,
      wheel_position: 833,
    },
    {
      id: 22,
      created_at: "2023-02-03T15:22:47.829Z",
      updated_at: "2023-02-03T15:22:47.829Z",
      wheel_id: 2083,
      network_id: "tron",
      amount: 1,
      locked: false,
      probability: 0,
      wheel_position: 811,
    },
    {
      id: 29,
      created_at: "2023-02-03T15:22:47.906Z",
      updated_at: "2023-02-03T15:22:47.906Z",
      wheel_id: 2083,
      network_id: "polygon",
      amount: 0.05,
      locked: false,
      probability: 0,
      wheel_position: 789,
    },
    {
      id: 17,
      created_at: "2023-02-03T15:22:47.774Z",
      updated_at: "2023-02-03T15:22:47.774Z",
      wheel_id: 2083,
      network_id: "bitcoin",
      amount: 1,
      locked: false,
      probability: 0,
      wheel_position: 765,
    },
    {
      id: 26,
      created_at: "2023-02-03T15:22:47.869Z",
      updated_at: "2023-02-03T15:22:47.869Z",
      wheel_id: 2083,
      network_id: "ethereum",
      amount: 0.15,
      locked: false,
      probability: 0,
      wheel_position: 742,
    },
  ];
  const handleSignupOpen = () => {
    setSignUpOpen(true);
    setOpen(false);
  };
  const { t } = useTranslation();
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="signup-spin-main-containr-res" sx={style}>
          <div className="signup-spin-main-containr second-scaler-animation">
            <div className="signup-spin-inner-container">
              <div className="signup-spin-content-container">
                <div
                  style={{
                    left: "30px",
                    zIndex: "1",
                  }}
                  className="signup-spin-left-container width-45 absolute"
                >
                  <div className="signup-spin-left-content relative">
                    {prizes.map((prize) => (
                      <div
                        style={{
                          //   background: "orange",
                          background: "transparent",
                          top: "47%",
                          height: "25px",
                          transform: `rotate(${prize.wheel_position}deg)`,
                          padding: "0 20px",
                        }}
                        className="spin-prize absolute width my_row center_align end z-index-1"
                      >
                        <div className="network-img-container">
                          <span className="bold">{prize.amount}</span>
                          <img
                            style={{
                              maxWidth: "30px",
                              marginLeft: "6px",
                            }}
                            src={`/imgs/AcceptedNetworks/${prize.network_id}.webp`}
                            alt=""
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  style={{
                    right: "30px",
                  }}
                  className="signup-spin-right-container width-55 absolute"
                >
                  <div className="signup-spin-right-content relative">
                    <div className="spin-signup-right-content absolute width height z-index-1">
                      <img className="spinnerCat" src="/imgs/cat.webp" alt="" />
                      <div className="spin-signup-right-content-inner">
                        <div
                          style={{
                            transform: "rotate(-11deg)",
                          }}
                          className="daily-text my_row end"
                        >
                          <span
                            style={{
                              fontSize: "70px",
                            }}
                            className="bold"
                          >
                            {t("header.signup.daily")}
                          </span>
                        </div>
                        <div
                          style={{
                            transform: "rotate(-11deg)",
                          }}
                          className="win-text my_row end"
                        >
                          <span
                            style={{
                              fontSize: "70px",
                            }}
                            className="bold"
                          >
                            {t("header.signup.win")}
                          </span>
                        </div>
                      </div>
                      <div className="spin-signup-right-content-inner">
                        <div
                          style={{
                            transform: "rotate(-11deg)",
                          }}
                          className="daily-text my_row end"
                        >
                          <span
                            style={{
                              fontSize: "70px",
                            }}
                            className="bold"
                          >
                            {t("header.signup.daily")}
                          </span>
                        </div>
                        <div
                          style={{
                            transform: "rotate(-11deg)",
                          }}
                          className="win-text my_row end"
                        >
                          <span
                            style={{
                              fontSize: "70px",
                            }}
                            className="bold"
                          >
                            {t("header.signup.win")}
                          </span>
                        </div>
                      </div>
                      <div className="spin-signup-right-content-inner">
                        <div
                          style={{
                            transform: "rotate(-11deg)",
                          }}
                          className="daily-text my_row end"
                        >
                          <span
                            style={{
                              fontSize: "70px",
                            }}
                            className="bold"
                          >
                            {t("header.signup.daily")}
                          </span>
                        </div>
                        <div
                          style={{
                            transform: "rotate(-11deg)",
                          }}
                          className="win-text my_row end"
                        >
                          <span
                            style={{
                              fontSize: "70px",
                            }}
                            className="bold"
                          >
                            {t("header.signup.win")}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="spinner-spin-the-wheel z-index-1">
                      <p>{t("header.signup.spinthewheel")}</p>
                      <p>{t("header.signup.winfreecrypto")}</p>
                    </div>
                    <button
                      className="primary-btn primary-btn-bg spinner-btn z-index-1"
                      onClick={handleSignupOpen}
                    >
                      {t("header.signup.signup")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}