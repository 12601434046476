import "./Crash.css";
import Classic from "./Classic/Classic";
import Data from "./AllBets/Data";
import BetStats from "../../Common/Stats/Stats";
import { betPriceContext } from "../../Contexts/BetPrice";
import { useContext, useState } from "react";
import Undermaintenance from "../../Common/Undermaintenance";
import { modalsControl } from "../../Contexts/ModalsControl";
import { useEffect } from "react";

const Crash = () => {
  const { insuficientWallet } = useContext(betPriceContext);
  const [underMaintenance, setUndermaintenance] = useState(false);
  const { showTrends, setShowTrends } = useContext(modalsControl);
  useEffect(() => {
    document.title =
      "Play Crypto Crash game and Win Big with Cryptocurrency - BCW CLUB";

    if (!showTrends) {
      setShowTrends(true);
    }
  }, []);
  if (underMaintenance) {
    return <Undermaintenance />;
  } else
    return (
      <div
        style={{
          transition: ".5s",
        }}
        className="crash-game-main-container"
      >
        <div className="crash-game-inner-container">
          <div
            style={{
              justifyContent: "space-between",
              overflow: "hidden",
            }}
            className="crash-game-content-container my_row relative"
          >
            <div className="Crash-graph-container">
              <Classic />
            </div>

            <div
              style={{
                transition: ".5s",
              }}
              className={`crash-data-container relative`}
            >
              <Data />
            </div>
          </div>
          <div className="crashGame-history-container">
            <BetStats />
          </div>
        </div>
      </div>
    );
};

export default Crash;
