import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import { useTranslation, Trans } from "react-i18next";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: "#1e2024",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function VerificationDescisionModal({
  setVirificationDescision,
  setNewSeed,
}) {
  const handleClose = () => setVirificationDescision(false);
  const handleVerify = () => {
    setNewSeed(true);
    handleClose(true);
  };
  const { t } = useTranslation();
  return (
    <div>
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="mb-5 text-center">
          {t("header.verificationbets.desion")}
          </div>
          <div className="width my_row space-evenly">
            <button
              className="bold white primary-btn primary-btn-disable"
              onClick={handleClose}
            >
              {t("header.verificationbets.Cancel")} 
            </button>
            <button
              className="bold white primary-btn primary-btn-bg"
              onClick={handleVerify}
            >
             {t("header.verificationbets.Verify")} 
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
