import React, { useState, useContext, useEffect } from "react";
// Redux imports
import { useSelector } from "react-redux";
// Context Imports
import { betPriceContext } from "../../../Contexts/BetPrice";
// ShowComponent import
import ShowComponent from "../../../Common/ShowComponent/ShowComponent";
import { useTranslation, Trans } from "react-i18next";
const MineArea = ({
  betPlacer,
  cashout,
  placed,
  RandomlyTile,
  alertMessage,
  configuredCurrency,
  betValues,
  setBetValues,
  inProgressBet,
  activeBetWallet,
}) => {
  const mines = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24,
  ];

  // Context states
  const { activatedCurrency } = useContext(betPriceContext);
  // Redux states
  const conversion_rates = useSelector((state) => state.game.conversion_rates);
  // Controlled states
  const [showSlider, setShowSlider] = useState(false);
  const [usdRate, setUsdRate] = useState("");
  const [calculatedUsdRate, setCalculatedUsdRate] = useState("");
  const [mode, setMode] = useState("manual");
  const current_payout = useSelector((state) => state.minesBet);
  const { t } = useTranslation();
  // Form values setter
  const handleOnChange = (e) => {
    if (inProgressBet) {
      return;
    }
    const { name, value } = e.target;
    setBetValues({ ...betValues, [name]: value });
  };
  // Helper functions
  const handleMode = (e) => {
    if (inProgressBet) {
      return;
    }
    const { id } = e.target;
    setMode(id);
  };
  const handleToggleSlider = () => {
    if (inProgressBet) {
      return;
    }
    setShowSlider(!showSlider);
  };
  const minBetSetter = () => {
    setBetValues({
      ...betValues,
      betAmount: parseFloat(configuredCurrency?.min_bet).toFixed(
        configuredCurrency?.precision
      ),
    });
  };
  const maxBetSetter = () => {
    setBetValues({
      ...betValues,
      betAmount: parseFloat(configuredCurrency?.max_bet).toFixed(
        configuredCurrency?.precision
      ),
    });
  };
  const handleMinBetAmount = () => {
    minBetSetter();
  };
  const handleMaxBetAmount = () => {
    maxBetSetter();
  };
  const multiplyBetAmount = () => {
    if (inProgressBet) {
      return;
    }
    if (parseFloat(betValues?.betAmount) * 2 < configuredCurrency?.max_bet) {
      setBetValues({
        ...betValues,
        betAmount: (parseFloat(betValues?.betAmount) * 2).toFixed(
          configuredCurrency?.precision
        ),
      });
    } else {
      maxBetSetter();
    }
  };
  const divideBetAmount = () => {
    if (inProgressBet) {
      return;
    }
    if (parseFloat(betValues?.betAmount) / 2 > configuredCurrency?.min_bet) {
      setBetValues({
        ...betValues,
        betAmount: (parseFloat(betValues?.betAmount) / 2).toFixed(
          configuredCurrency?.precision
        ),
      });
    } else {
      minBetSetter();
    }
  };
  // Input blur functions
  const handlePayoutBlur = (e) => {
    const { name, value } = e.target;
    if (!value) {
      setBetValues({ ...betValues, [name]: 1.01 });
    } else if (parseFloat(value) <= 0 || parseFloat(value) < 1.01) {
      setBetValues({ ...betValues, [name]: 1.01 });
    } else if (parseFloat(value) > 1000000.0) {
      setBetValues({ ...betValues, [name]: (1000000).toFixed(2) });
    } else {
      setBetValues({ ...betValues, [name]: parseFloat(value).toFixed(2) });
    }
  };
  const handleBetAmountBlur = (e) => {
    const { name, value } = e.target;
    if (!value || parseFloat(value) < 0) {
      minBetSetter();
    } else if (parseFloat(value) < configuredCurrency?.min_bet) {
      minBetSetter();
    } else if (parseFloat(value) > configuredCurrency?.max_bet) {
      maxBetSetter();
    } else {
      setBetValues({
        ...betValues,
        [name]: parseFloat(value).toFixed(configuredCurrency?.precision),
      });
    }
  };

  // Effects

  // USD rate finder
  useEffect(() => {
    setUsdRate(
      conversion_rates.conversion_rates.find(
        (rate) =>
          rate.from_currency === activatedCurrency.network_id &&
          rate.to_currency === "united_states_dollar"
      )
    );
  }, [activatedCurrency.network_id]);

  // USD rate calculater
  useEffect(() => {
    setCalculatedUsdRate(
      (parseFloat(usdRate?.rate) * parseFloat(betValues?.betAmount)).toFixed(4)
    );
  }, [betValues?.betAmount, usdRate?.rate]);

  useEffect(() => {
    if (!configuredCurrency?.min_bet || placed) {
      return;
    }
    minBetSetter();
  }, [configuredCurrency?.min_bet, activatedCurrency.network_id]);

  return (
    <div className="col-lg-3 mb-4 p-0 dark-bg-four order-lg-first">
      <div className="p-3 game-play-area">
        <div className="d-flex text-center gap-1 fs-14">
          <button
            id="manual"
            className={`w-100 py-3 radius-3 orbitron-font ${
              mode === "manual" ? "dark-bg-two secondary_text" : "text_color"
            }`}
            onClick={handleMode}
          >
            {t("header.referral.manual")}
          </button>
          <button
            id="auto"
            className={`w-100 py-3 radius-3 orbitron-font ${
              mode === "auto" ? "dark-bg-two secondary_text" : "text_color"
            }`}
            onClick={handleMode}
          >
            {t("header.referral.auto")}
          </button>
        </div>

        <ShowComponent condition={mode === "manual"}>
          <div className="mt-4 mb-4">
            <div className="">
              <div className="d-flex justify-content-between mb-2 fs-14 text-color-secondary">
                <p className="mb-0">
                  {t("header.wallet.transactionTab.amount")} &nbsp;
                </p>
                <p className="mb-0">
                  <span className="blue_color">
                    {activatedCurrency.network_id === "wb"
                      ? "0"
                      : !calculatedUsdRate || calculatedUsdRate === "NaN"
                      ? "..."
                      : calculatedUsdRate}
                  </span>
                  &nbsp;USD
                </p>
              </div>
              <div className="position-relative">
                <img
                  src={`/imgs/AcceptedNetworks/${
                    placed ? activeBetWallet : activatedCurrency?.network_id
                  }.webp`}
                  alt={activatedCurrency?.network_id}
                  className="ms-3 position-absolute currency-icons top-50 translate-middle-y"
                />
                <div className="d-flex end-0 me-2 position-absolute top-50 translate-middle-y">
                  <button
                    className="dark-bg-one ms-1 p-2 radius-3 text-color-secondary cursor-pointer"
                    onClick={divideBetAmount}
                  >
                    <small className="fs-13">/</small>2
                  </button>
                  <button
                    className="dark-bg-one ms-1 p-2 radius-3 text-color-secondary cursor-pointer"
                    onClick={multiplyBetAmount}
                  >
                    x2
                  </button>
                  <button
                    className="cursor-pointer dark-bg-one ms-1 p-2 radius-3 text-color-secondary range-slider-icon"
                    onClick={handleToggleSlider}
                  >
                    <img
                      src="/assets/plinko/svg/price-slider.svg"
                      alt=""
                      style={{ width: "10px" }}
                    />
                  </button>
                </div>
                <input
                  type="number"
                  name="betAmount"
                  className="form-control ps-5"
                  placeholder={t("stats.highrollers.betamount")}
                  value={betValues.betAmount}
                  onChange={handleOnChange}
                  onBlur={handleBetAmountBlur}
                  disabled={inProgressBet}
                />
              </div>
            </div>
            <ShowComponent condition={showSlider}>
              <div className={`mt-4 d-flex gap-3 align-items-center`}>
                <button
                  className="dark-bg-one fs-13 p-2 radius-3 text-color-secondary"
                  onClick={handleMinBetAmount}
                >
                  {t("header.wallet.withdrawTab.min")}
                </button>
                <input
                  type="range"
                  name="betAmount"
                  className="form-range"
                  id="customRange1"
                  step="0.000001"
                  disabled={inProgressBet}
                  value={betValues?.betAmount}
                  onChange={handleOnChange}
                  min={parseFloat(configuredCurrency?.min_bet)}
                  max={parseFloat(configuredCurrency?.max_bet).toFixed(
                    configuredCurrency?.precision
                  )}
                />
                <button
                  className="dark-bg-one fs-13 p-2 radius-3 text-color-secondary"
                  onClick={handleMaxBetAmount}
                >
                  {t("header.wallet.swapTab.max")}
                </button>
              </div>
            </ShowComponent>

            <div className="mt-4">
              <div className="d-flex justify-content-between mb-2 fs-14 text-color-secondary">
                <p className="mb-0">{t("header.sidebar.Mine")}</p>
              </div>
              <div class="input-label-container input-color">
                <div>
                  <select
                    className="form-control uppercase"
                    name="mines"
                    id="mines"
                    value={betValues.mines}
                    onChange={handleOnChange}
                    disabled={inProgressBet}
                  >
                    {mines.map((mine) => (
                      <option value={mine}>{mine}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          {placed ? (
            <div>
              <div className="d-flex justify-content-between mb-2 fs-14 text-color-secondary">
                <p className="mb-0">
                  {t("header.sidebar.ProfitonNextTile")}{" "}
                  {current_payout.next_multiplier} X
                </p>
              </div>
              <input
                type="number"
                name="nextmultiplier"
                className="form-control mb-3"
                value={current_payout.next_payout}
                onBlur={handlePayoutBlur}
                onChange={handleOnChange}
              />
              <div className="d-flex justify-content-between mb-2 fs-14 text-color-secondary">
                <p className="mb-0">
                  {t("header.sidebar.TotalProfit")}
                  {current_payout.current_multiplier}X
                </p>
              </div>
              <input
                type="number"
                name="currentmultiplier"
                className="form-control"
                value={current_payout.current_payout}
                onBlur={handlePayoutBlur}
                onChange={handleOnChange}
              />
              <button
                className="orbitron-font primary-btn bet-button-bubbles-cancel w-100 mb-3 mt-4"
                onClick={RandomlyTile}
              >
                {t("header.sidebar.tail")}
              </button>
              <div className="">
                <button
                  className="orbitron-font primary-btn primary-btn-bg bet-button-bubbles w-100"
                  onClick={cashout}
                >
                  {t("gamebox.betit.payout")}
                </button>
              </div>
            </div>
          ) : (
            <div className="">
              <button
                className="orbitron-font primary-btn primary-btn-bg bet-button-bubbles w-100"
                onClick={betPlacer}
              >
                {t("gamebox.betit.placebet")}
              </button>
            </div>
          )}
          <ShowComponent condition={alertMessage.length}>
            <div className="alert alert-danger m-0 mt-4 mb-4 text-center">
              {alertMessage}
            </div>
          </ShowComponent>
        </ShowComponent>
        <ShowComponent condition={mode === "auto"}>
          <div class="alert alert-success m-0 mt-4 mb-4 text-center">
            {t("header.referral.coming")}
          </div>
        </ShowComponent>
      </div>
    </div>
  );
};
export default MineArea;
