import React, { useState, useContext, memo, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import SpinPrizeModal from "./SpinPrizeModal";
import { modalsControl } from "../../Contexts/ModalsControl";
import WheelData from "../../../api/spin_wheel";
import BcwDataApi from "../../../api/bcw_data";
import { setbcwData, setWheelStatus, setWheelTime } from "../../../store/user";
import { useDispatch, useSelector } from "react-redux";
import UserWalletsAPI from "../../../api/user_wallets";
import { setUserWallets } from "../../../store/user";
import CloseIcon from "@mui/icons-material/Close";
import useWindowDimensions from "../../Contexts/windowDimensions";
import Countdown from "react-countdown";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import boopSfx from "./Sounds/spansound.wav";
import Sound from "./Sounds/pricesound.wav";
import useSound from "use-sound";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  background: "rgb(108 108 108 / 18%)",
  borderRadius: "5px",
  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  backdropFilter: "blur(8px)",
  " -webkit-backdrop-filter": "blur(8px)",
  boxShadow: "24",
  outline: "none",
  background: "rgb(40 40 40 / 40%)",
};

function BasicModal() {
  const { t } = useTranslation();
  const wheelTime = useSelector((state) => state.user.wheelTime);
  const wheelStatus = useSelector((state) => state.user.wheelStatus);
  const currentUser = useSelector((state) => state.user.currentUser);
  const navigate = useNavigate();
  const [wheelPrize, setWheelPrize] = useState([]);

  const { spinWheel, setSpinWheel } = useContext(modalsControl);
  const [LoadingSpin, setLoadingSpin] = useState(true);

  const [wheelSpinPosition, setWheelSpinPosition] = useState(0);
  const dispatch = useDispatch();

  const handleClose = () => {
    setSpinWheel(false);
    setWheelSpinPosition(0);
  };

  const [prizePop, setPrizePop] = useState(false);

  const [wheelId, setWheelId] = useState(0);
  const [prize, setPrize] = useState({});
  const [loadPrize, setLoadPrize] = useState(false);
  const [canSpin, setCanSpin] = useState(false);
  const [reload, setReload] = useState(true);
  const [trigger, setTrigger] = useState(true);
  useEffect(() => {
    setLoadingSpin(true);
    WheelData.GetWheelData()
      .then((res) => {
        setWheelPrize(res.data.prizes);
        setLoadingSpin(false);
      })
      .catch((err) => {
        console.log(err);
      });
    // spinWheel ? setSpinWheel(false) : setSpinWheel(true);
  }, []);
  const winningPositions = [
    1080, 1058, 1037, 1013, 991, 968, 945, 922, 899, 878, 855, 833, 811, 789,
    765, 742,
  ];
  const prizePositions = [
    "rotate(2deg)",
    "rotate(24deg)",
    "rotate(47deg)",
    "rotate(70deg)",
    "rotate(91deg)",
    "rotate(113deg)",
    "rotate(135deg)",
    "rotate(158deg)",
    "rotate(180deg)",
    "rotate(201deg)",
    "rotate(224deg)",
    "rotate(247deg)",
    "rotate(269deg)",
    "rotate(292deg)",
    "rotate(316deg)",
    "rotate(339deg)",
  ];

  useEffect(() => {
    setWheelId(wheelPrize[0]?.wheel_id);
  }, [wheelPrize]);

  const resetTime = () => {
    currentUser &&
      WheelData.GetWheelStatus()
        .then((res) => {
          setTrigger(false);
          dispatch(
            setWheelStatus({
              wheelStatus: res.data.can_spin,
            })
          );
          dispatch(
            setWheelTime({
              wheelTime: res.data.time,
            })
          );
        })
        .catch((err) => {
          console.log(err);
        });
  };

  const [spinSound] = useSound(boopSfx);
  const [priceSound] = useSound(Sound);
  const spinNow = () => {
    if (wheelStatus) {
      setLoadPrize(true);
      WheelData.GetWheelPrize({
        wheel: {
          wheel_id: wheelId,
        },
      })
        .then((res) => {
          spinSound();

          setPrize(res.data);
          setWheelSpinPosition(res.data.prize.wheel_position);
          BcwDataApi.getBcwData().then((res) => {
            dispatch(
              setbcwData({
                bcwData: res.data.bcw,
              })
            );
          });
          setTimeout(() => {
            setPrizePop(true);
            priceSound();
          }, 4000);
        })
        .catch((err) => {
          console.log(err);
          setLoadPrize(false);
        });
    }
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return resetTime();
    } else {
      return (
        <span className="primary-btn my_row primary-btn-spinner">
          {days <= 9 && "0"}
          {days}: {hours <= 9 && "0"}
          {hours}:{minutes <= 9 && "0"}
          {minutes}:{seconds <= 9 && "0"}
          {seconds}
        </span>
      );
    }
  };

  useEffect(() => {
    currentUser &&
      WheelData.GetWheelStatus()
        .then((res) => {
          dispatch(
            setWheelStatus({
              wheelStatus: res.data.can_spin,
            })
          );
          dispatch(
            setWheelTime({
              wheelTime: res.data.time,
            })
          );
        })
        .catch((err) => {
          console.log(err);
        });
  }, [spinWheel]);

  return (
    <div>
      <Modal
        className="prize-wheel-modal-main"
        open={spinWheel}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          className="modals-height-on-mobiles modals-height-on-mobiles-spinner"
        >
          <SpinPrizeModal
            prizePop={prizePop}
            setPrizePop={setPrizePop}
            prize={prize}
            resetTime={resetTime}
            setSpinWheel={setSpinWheel}
            setWheelSpinPosition={setWheelSpinPosition}
            setLoadPrize={setLoadPrize}
          />
          {LoadingSpin && (
            <div
              style={{
                left: "165px",
                position: "absolute",
                top: "196px",
                zIndex: "1",
              }}
              className="lds-ellipsis"
            >
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          )}

          <div
            style={{
              transform: !LoadingSpin ? "scale(1)" : "scale(0)",
              transition: ".5s",
            }}
            className="spinner-main-container"
          >
            <div
              style={{
                padding: "10px 30px 15px 33px",
                zIndex: 2,
              }}
              className="prize-wheel-head-container my_row space-between relative"
            >
              <span className="orbitron-font">{t("header.span.wheel")}</span>
              <button className="secondary_text" onClick={handleClose}>
                <CloseIcon
                  style={{
                    color: "white",
                  }}
                />
              </button>
            </div>
            <div
              style={{
                marginTop: "-70px",
              }}
              className="spinner-inner-container"
            >
              <div className="spinner-content-contaei">
                <div className="spinner-assemply relative">
                  <div
                    style={{
                      left: "248px",
                      top: "188px",
                      zIndex: "1",
                      position: "relative",
                    }}
                    className="spinner-pointer-container relative"
                  >
                    <img
                      style={{ maxWidth: "32%" }}
                      src="/imgs/spinnerPointer.png"
                      alt=""
                    />
                  </div>

                  <div
                    style={{
                      transition: "3s",
                      transform: `rotate(${wheelSpinPosition}deg)`,
                    }}
                    className="spinner-spinner-container relative justified"
                  >
                    {wheelPrize.map((prize, index) => {
                      return (
                        <div
                          id={prize.id}
                          key={prize.id}
                          style={{
                            height: "19px",
                            // background: "orange",
                            top: "46%",
                            left: "16%",
                            position: "absolute",
                            zIndex: "2",
                            width: "277px",
                            transform: prizePositions[index],
                            paddingRight: "10px",
                            boxSizing: "border-box",
                          }}
                          className="prize-container my_row end"
                        >
                          <span className="bold">{prize.amount}</span>
                          <img
                            style={{
                              maxWidth: "17px",
                              background: "black",
                              borderRadius: "50%",
                              marginRight: "7px",
                              marginLeft: "3px",
                            }}
                            src={`/imgs/AcceptedNetworks/${prize.network_id}.webp`}
                            alt=""
                          />
                        </div>
                      );
                    })}

                    <img
                      style={{
                        maxWidth: "75%",
                      }}
                      src="/imgs/spinner.png"
                      alt=""
                    />
                  </div>

                  <img
                    className="relative lotery"
                    style={{
                      maxWidth: "20%",
                      zIndex: 1,
                      top: "-195px",
                      left: "161px",
                      bottom: " 222px",
                    }}
                    src="/imgs/text.png"
                    alt=""
                  />
                  <img
                    className="relative"
                    style={{
                      maxWidth: "26%",
                      bottom: "198px",
                      left: "69px",
                    }}
                    src="/imgs/center.png"
                    alt=""
                  />
                  <div
                    style={{
                      bottom: "80px",
                    }}
                    className="banner-bronze-container justified absolute width"
                  >
                    <img
                      style={{
                        maxWidth: "225px",
                      }}
                      src="/imgs/banner_bronze.webp"
                      alt=""
                    />
                  </div>

                  <div
                    style={{
                      bottom: "20px",
                    }}
                    className="spin-buttonContainer width absolute justified"
                  >
                    <div
                      style={{
                        width: "175px",
                        height: "46px",
                        padding: "14px 5px",
                      }}
                      className="justified"
                    >
                      {/* 86400000   */}

                      {wheelStatus ? (
                        <button
                          style={{
                            width: "135px",
                            height: "46px",
                          }}
                          className="white primary-btn primary-btn-spinner d-flex justify-content-center align-items-center"
                          onClick={spinNow}
                        >
                          {loadPrize ? (
                            <div
                              style={{
                                height: "0px",
                                bottom: "45px",
                              }}
                              className="lds-ellipsis relative"
                            >
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          ) : (
                            t("header.span.spannow")
                          )}
                        </button>
                      ) : (
                        <Countdown
                          date={wheelTime}
                          renderer={renderer}
                          onComplete={() => {
                            dispatch(
                              setWheelStatus({
                                wheelStatus: true,
                              })
                            );
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default memo(BasicModal);
