import { useEffect, useState, memo, React, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./Header.css";
import Wrapper from "../Modals/Wrapper/Wrapper";
import Modal from "../Modals/Modal";
import WalletInnerTabs from "./Wallet/Tabs/WalletInnerTabs";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import SwapCallsIcon from "@mui/icons-material/SwapCalls";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import { setUserProfile } from "../../../store/user";
import { betPriceContext } from "../../Contexts/BetPrice";
import { modalsControl } from "../../Contexts/ModalsControl";
import UserModal from "../../Games/Crash/AllBets/Individual/userModal";
import ChatIcon from "@mui/icons-material/Chat";
import BcwDataApi from "../../../api/bcw_data";
import { setbcwData } from "../../../store/user";
import useWindowDimensions from "../../Contexts/windowDimensions";
import SignInAPI from "../../../api/signin";
import { setAuthData } from "../../../store/user";
import { useTranslation, Trans } from "react-i18next";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import GrainIcon from "@mui/icons-material/Grain";
import CurrenciesPanel from "./Wallet/Currencies/CurrenciesPanel";

const Header = ({ onClick, handleReferEarn }) => {
  const { width } = useWindowDimensions();

  const {
    walletOpen,
    setWalletOpen,
    setValue,
    setProfileModal,
    setTransactionsModal,
    selector,
    setSelector,
  } = useContext(modalsControl);
  const { activatedCurrency, setActivatedCurrency } =
    useContext(betPriceContext);
  const currentUser = useSelector((state) => state.user.currentUser);
  const wallets = useSelector((state) => state.user.wallets);
  const user_profile = useSelector((state) => state.user.user_profile);
  const betActive = useSelector((state) => state.bets.betActive);
  const [isDisable, setIsDisable] = useState(false);
  const nextRoundBetPlaced = useSelector(
    (state) => state.game.next_round_bet_placed
  );

  useEffect(() => {
    if (betActive || nextRoundBetPlaced) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [betActive, nextRoundBetPlaced]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigator = () => {
    navigate("/");
  };
  const referalmanagement = () => {
    navigate("/referal-management/referal-dashboard");
  };
  const Wallet = () => {
    setValue(0);
    setWalletOpen(true);
  };
  const handleLogOut = () => {
    if (!isDisable) {
      setUserProfile({
        user_profile: null,
      });
      SignInAPI.logout()
        .then((res) => {})
        .catch((err) => {});
      dispatch(
        setAuthData({
          currentUser: null,
          token: null,
        })
      );
      localStorage.removeItem("token");
    }
  };

  const enableSelector = () => {
    if (!isDisable) {
      !selector &&
        BcwDataApi.getBcwData().then((res) => {
          dispatch(
            setbcwData({
              bcwData: res.data.bcw,
            })
          );
        });
      selector ? setSelector(false) : setSelector(true);
    }
  };

  useEffect(() => {
    if (currentUser) {
      setActivatedCurrency(wallets?.find((element) => element.default_wallet));
    }
  }, [wallets]);

  const handleOpen = () => {
    if (!isDisable) {
      setWalletOpen(true);
    }
  };
  const handleSwap = () => {
    setValue(3);
    setWalletOpen(true);
  };
  const handleProfile = () => {
    setProfileModal(true);
  };
  const handleTransactions = () => {
    setWalletOpen(true);
    setTransactionsModal(true);
  };
  const { t } = useTranslation();

  return (
    <>
      <div className="header-main-div">
        <div className="page-width">
          <div className="header-inner-div">
            <div className="header-content-div my_row space-between">
              <div className="header-logo-container my_row">
                <img
                  onClick={() => {
                    navigator();
                  }}
                  className="Logo-img large-logo"
                  src="/imgs/logo/logo.png"
                  alt="Logo"
                />

                <img
                  onClick={() => {
                    navigator();
                  }}
                  className="mascot-Logo-img small-logo"
                  src="/imgs/logo/LoginLogo.png"
                  alt="Logo"
                />
              </div>
              {wallets && currentUser ? (
                <div className="wallet-main-class">
                  <div className="wallet-inner-class my_row end">
                    <div className="userBetCurrency-main-container relative">
                      <button
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "start",
                          border: "none",
                          padding: "2px 16px 4px",
                          width: "100%",
                          borderRadius: "5px 0px 0px 5px",
                          height: "100%",
                        }}
                        className="userBetCurrency-container my_row darken-bg"
                        onClick={enableSelector}
                      >
                        <div className="userBetCurrency my_row white justified">
                          <div className="userBetCurrencyimg justified">
                            <img
                              style={{
                                maxWidth: "1rem",
                                maxHeight: "1rem",
                              }}
                              src={`/imgs/AcceptedNetworks/${activatedCurrency?.network_id}.webp`}
                              alt={activatedCurrency?.coin}
                            />
                          </div>
                          <div
                            style={{
                              margin: "0 6px",
                              textTransform: "uppercase",
                            }}
                            className="currency-name secondary_text font-500"
                          >
                            {activatedCurrency?.symbol}
                          </div>
                          <div className="drop-icon text_color justified">
                            <ArrowDropDownIcon />
                          </div>
                        </div>
                        <div className="userBalance white font-600">
                          {activatedCurrency?.fake_amount}
                        </div>
                      </button>
                      {selector && (
                        <CurrenciesPanel
                          selector={selector}
                          setSelector={setSelector}
                          isDisable={isDisable}
                        />
                      )}
                    </div>
                    <div className="wallet-content-class white my_row justify">
                      {walletOpen && (
                        <Modal>
                          <WalletInnerTabs />
                        </Modal>
                      )}

                      <button
                        style={{
                          color: "white",
                        }}
                        onClick={handleOpen}
                        className={`primary-btn primary-btn-bg`}
                        id="wallet-button"
                      >
                        <span className="">
                          <AccountBalanceWalletIcon />
                        </span>

                        {t("header.wallet.wallet")}
                      </button>
                    </div>
                    <div className="my_row justified header-top-profile-pic">
                      <div className="profileView-contentContainer my_row white">
                        <div
                          style={{
                            margin: "0 10px",
                          }}
                          className="profile-typo justified"
                        >
                          <div
                            style={{
                              cursor: "pointer",
                            }}
                            className="userName my_row"
                          >
                            <UserModal
                              userImg={
                                user_profile?.user_img?.url
                                  ? user_profile?.user_img?.url
                                  : user_profile?.user_img
                              }
                              Text={
                                user_profile?.username
                                  ? user_profile?.username
                                  : "username"
                              }
                              userId={user_profile?.user}
                            />
                          </div>
                        </div>

                        <div
                          className="profile-expand-icon justified relative userProfileView darken-bg"
                          style={{
                            padding: "0 12px",
                            margin: "-11px 10px -11px 0",
                          }}
                        >
                          <MenuIcon className="text_color" />
                          <div
                            style={{
                              padding: "15px",
                            }}
                            className="userprofile-logout-extend absolute"
                          >
                            <div
                              className="hover-userProfile-content my_row space-around"
                              style={{ justifyContent: "flex-start" }}
                            >
                              <div className="user-profile-img-container justified margin-right-10">
                                <img
                                  style={{
                                    width: "3rem",
                                    borderRadius: "50%",
                                  }}
                                  src={
                                    user_profile?.user_img?.url
                                      ? user_profile?.user_img?.url
                                      : user_profile?.user_img
                                  }
                                  alt="userProfile"
                                />
                              </div>

                              <div className="hover-user-account-status">
                                <div className="userName">
                                  <span className="">
                                    {user_profile?.username.substring(0, 10)}
                                    {user_profile?.username.length > 10 &&
                                      "..."}
                                  </span>
                                </div>
                                <div
                                  style={{
                                    marginTop: "5px",
                                  }}
                                  className="userAccountbalance my_row"
                                >
                                  <img
                                    style={{
                                      width: "1.225rem",
                                      marginRight: "5px",
                                    }}
                                    src={activatedCurrency?.Coinimg}
                                    alt=""
                                  />
                                  <span
                                    className=""
                                    style={{ fontSize: "14px" }}
                                  >
                                    {activatedCurrency?.coin == "bcd"
                                      ? "BCw"
                                      : activatedCurrency?.coin}
                                  </span>
                                </div>
                              </div>
                            </div>
                            {/* <Routes>
                                <Route
                                  exact
                                  path="/referal-management"
                                  element={<ReferralManagement />}
                                ></Route>
                              </Routes> */}
                            <div className="my_row margin-top-10 space-between header-menu-buttons-container">
                              <button
                                className="text_color width-48 left_text"
                                onClick={handleProfile}
                              >
                                <PersonIcon />
                                <span>{t("header.referral.userinfo")}</span>
                              </button>
                              <button
                                className="text_color width-48 left_text my_row"
                                onClick={Wallet}
                              >
                                <AccountBalanceWalletIcon />
                                <span>{t("header.wallet.wallet")}</span>
                              </button>
                              <button
                                className="text_color width-48 left_text"
                                onClick={handleProfile}
                              >
                                <SignalCellularAltIcon />
                                <span>{t("header.referral.stat")}</span>
                              </button>
                              <button
                                className="text_color width-48 left_text"
                                onClick={handleSwap}
                              >
                                <SwapCallsIcon />
                                <span>{t("header.referral.bcs")}</span>
                              </button>
                              <button
                                className="text_color width-48 left_text"
                                onClick={handleTransactions}
                              >
                                <CurrencyExchangeIcon />
                                <span>{t("header.referral.trans")}</span>
                              </button>
                              <button
                                className="text_color width-48 left_text"
                                onClick={referalmanagement}
                              >
                                <GrainIcon />
                                <span>{t("header.referral.Affiliate")}</span>
                              </button>

                              <button
                                className="text_color width-48 left_text"
                                onClick={handleReferEarn}
                              >
                                <PersonAddAlt1Icon />
                                <span>{t("header.referral.Referfriend")}</span>
                              </button>
                            </div>
                            {/* <Link to="/signin"> */}
                            <button
                              className="hover-logout-container width text_color"
                              onClick={handleLogOut}
                            >
                              <span>
                                <LogoutIcon /> {t("header.referral.Logout")}
                              </span>
                            </button>
                            {/* </Link> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      // style={{
                      //   marginLeft: "5px",
                      // }}
                      className="spinner-chatIcon-container justified"
                    >
                      <div className="chat-button-container justified">
                        <button
                          className="text_color justified"
                          onClick={onClick}
                        >
                          <ChatIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className={`signin-signup-actions-container my_row ${
                      width <= 767 && !currentUser && ""
                    } center_align ${width <= 767 && "end"}`}
                  >
                    <div className="actions-signin-container my_row justify">
                      <Wrapper
                        Text={t("header.signin.signin")}
                        linkTo="signin"
                      />
                    </div>
                    <div className="actions-signup-container my_row justify">
                      <Wrapper
                        Text={t("header.signup.signup")}
                        linkTo="signup"
                      />
                    </div>

                    <div style={{}} className="chat-button-container justified ms-2">
                      <button
                        className="text_color justified"
                        onClick={onClick}
                      >
                        <ChatIcon />
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(Header);
