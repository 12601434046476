import React from "react";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReferalManagmentAPI from "../../api/referal_managment";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { setSelectedUserProfile } from "../../store/game";
import { Tooltip } from "@mui/material";
import UserProfilesAPI from "../../api/user_profiles";
import { padding } from "@mui/system";
import UserWalletsAPI from "../../api/user_wallets";
import { setUserWallets } from "../../store/user";
import { modalsControl } from "../Contexts/ModalsControl";

function ReferralManagement() {
  const selected_user_profile = useSelector((state) => state.user.user_profile);
  const { referalLinkModal, setReferalLinkModal } =
    React.useContext(modalsControl);
  const handleOpen = () => setReferalLinkModal(true);
  const handleClose = () => setReferalLinkModal(false);
  const [data, setData] = useState([]);
  const [refData, setRefData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState(false);
  const [freezeTriggered, setFreezeTriggered] = useState(false);
  const [usernamePredicate, setUsernamePredicate] = useState("cont");
  const [filterUsername, setFilterUsername] = useState("");
  const [claimedPredicate, setClaimedPredicate] = useState("not_null");
  const [filterClaimed, setFilterClaimed] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  /////////////////////////////////////////////////////
  const [referalStats, setReferalStats] = useState([]);
  const [claimable, setClaimable] = useState(false);
  const [referalClaim, setReferalClaim] = useState(false);
  const [claimTriggered, setClaimTriggered] = useState(false);
  const [copy, setCopy] = useState(false);
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.currentUser);
  const handleCopy = (tocopy) => {
    navigator.clipboard.writeText(tocopy);
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 3000);
  };
  let params = useParams();

  useEffect(() => {
    UserProfilesAPI.referal_stats()
      .then((res) => {
        setReferalStats(res.data.referal_stats);
        setClaimable(res.data.claimable);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [referalClaim, claimTriggered]);

  const claimReward = () => {
    UserProfilesAPI.referal_claim()
      .then((res) => {
        setClaimTriggered(!claimTriggered);
        UserWalletsAPI.getUserWallets()
          .then((response) => {
            dispatch(
              setUserWallets({
                wallets: response.data,
              })
            );
          })
          .catch((error) => {
            console.log(error);
          });
        referalClaim ? setReferalClaim(false) : setReferalClaim(true);
        setClaimTriggered(!claimTriggered);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    ReferalManagmentAPI.viewUserReferalData(
      params,
      page,
      filter,
      filterUsername,
      usernamePredicate,
      startDate,
      endDate,
      claimedPredicate,
      filterClaimed
    ).then((response) => {
      setData(response.data);
      setFilterClaimed(response.data.filterClaimed);
      setRefData(response.data.result);
      setTotalPages(response.data.total_pages);
    });
  }, [
    claimTriggered,
    page,
    filter,
    filter && freezeTriggered,
    filter && filterUsername,
    filter && usernamePredicate,
    filter && startDate,
    filter && endDate,
    filter && claimedPredicate,
    filter && filterClaimed,
  ]);

  const claimedStatus = () => {
    if (claimedPredicate === "not_false") {
      setFilterClaimed(1);
    } else if (claimedPredicate === "false") {
      setFilterClaimed(1);
    } else if (claimedPredicate === "not_null") {
      setFilterClaimed(1);
    }
  };

  const { t } = useTranslation();
  return (
    <div className="main-wrapper-container">
      <div className="affiliate-search">
        <h1 className="orbitron-font">
          {t("header.referral.referralmanagement")}
        </h1>
        <div className="row">
          <div className="col-md-6">
            <div className="total-reward-total-friend">
              <p>{t("header.referral.TotalEarned")}</p>
              <h2>
                <b>${parseFloat(data.reward_earned).toFixed(2)}</b>
              </h2>
            </div>
          </div>
          <div className="col-md-6">
            <div className="total-reward-total-friend">
              <p>{t("header.referral.totalFriendsreferred")}</p>
              <h2>{data.player_refered}</h2>
            </div>
          </div>
        </div>
        <div className="">
          <div className="refere-page-buttons">
            <button
              className="primary-btn primary-btn-bg text_color margin-right-15 margin-bottom-15"
              onClick={handleOpen}
            >
              {t("header.referral.Referfriend")}
            </button>
            <button
              disabled={!claimable}
              className={
                claimable
                  ? "primary-btn primary-btn-bg margin-right-15 margin-bottom-15"
                  : "primary-btn primary-btn-disable margin-right-15 margin-bottom-15"
              }
              onClick={claimReward}
            >
              {t("header.referral.claimnow")}
            </button>
          </div>
          <Modal open={referalLinkModal} onClose={handleClose}>
            <Box className="ref">
              <div className="maindivrefpopup">
                <div
                  className="my_row space-between"
                  style={{
                    background: "#37383C",
                    height: "50px",
                    padding: "0px 15px",
                  }}
                >
                  <div className="">{t("header.referral.Referfriend")}</div>
                  <div className="">
                    <button className="text_color " onClick={handleClose}>
                      <CloseIcon />
                    </button>
                  </div>
                </div>
                <div className="margin-bottom-20 centered_text">
                  <div
                    class="box-2 margin-bottom-30 main-wrapper-container"
                    style={{ borderRadius: "0" }}
                  >
                    <div class="row">
                      <div class="col-md-7">
                        <div class="box-2-content">
                          <h1>{t("header.banner.reward")}</h1>
                          <p style={{ margin: "0" }}>
                            {t("header.banner.detail")}
                          </p>
                        </div>
                      </div>
                      <div class="col-md-5 centered_text">
                        <img
                          style={{
                            maxWidth: "200px",
                            width: "100%",
                          }}
                          src="/imgs/affiliate-secondBanner.png"
                          alt=""
                          class="width-auto referal-popup-img"
                        />
                      </div>
                    </div>
                  </div>
                  <span className="text_color">
                    {t("header.referral.shareyourreferralink")}
                  </span>
                </div>
                <div className="">
                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    className="user-stats"
                  >
                    <div
                      className="margin-bottom-20"
                      style={{
                        textAlign: "center",
                        padding: "10px",
                        background: "#292a2d",
                        borderRadius: "5px",
                        width: "90%",
                        margin: "auto",
                      }}
                    >
                      <button
                        className="text_color "
                        onClick={() =>
                          handleCopy(selected_user_profile?.referal_link)
                        }
                      >
                        <span>{selected_user_profile?.referal_link}</span>
                      </button>
                    </div>
                    <div className="centered_text padding-bottom-20">
                      <button
                        className="primary-btn primary-btn-bg"
                        onClick={() =>
                          handleCopy(selected_user_profile?.referal_link)
                        }
                      >
                        {copy
                          ? t("header.wallet.depositTab.copied")
                          : t("header.wallet.depositTab.copy")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
      <div className="affiliate-search-records">
        <table cellspacing="0" cellpadding="0">
          <thead>
            <tr>
              <th>{t("header.referral.userId")}</th>
              <th>{t("header.referral.username")}</th>
              <th>{t("header.referral.totalwager")}</th>
              <th>{t("header.referral.totalcommission")}</th>
              <th>{t("header.referral.claimed")}</th>
            </tr>
          </thead>
          <tbody>
            {referalStats.length ? (
              referalStats.map((obj) => {
                return (
                  <tr>
                    <td>
                      <p>{obj.user_id}</p>
                    </td>
                    <td>
                      <div className="center_align row justify">
                        {obj.username}
                        <img
                          src="images/polyClr.png"
                          alt=""
                          className="width-auto currency-icon"
                        />
                      </div>
                    </td>

                    <td>{parseFloat(obj.total_usd_wager).toFixed(2)}</td>
                    <td>{parseFloat(obj.total_usd_commision).toFixed(2)}</td>
                    <td>
                      {obj.claimed ? (
                        <p>{t("header.referral.claimed")}</p>
                      ) : (
                        <p>{t("header.referral.Notclaimed")}</p>
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="text-center" colSpan={5}>
                  <br />
                  <br />
                  <br />
                  <img src="/imgs/empty.png" alt="" className="width-auto" />
                  <br />
                  <br />
                  <br />
                  <p>{t("header.referral.oop")}</p>
                  <br />
                  <br />
                  <br />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ReferralManagement;
