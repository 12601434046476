import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
// Redux imports
import { useSelector } from "react-redux";
// Context Imports
import { modalsControl } from "../Contexts/ModalsControl";
// Component imports
import ShowComponent from "../Common/ShowComponent/ShowComponent";
import { useTranslation } from "react-i18next";
const settings = {
  autoplay: true,
  autoplaySpeed: 2000,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.user.currentUser);
  const {
    setSignUpOpen,
    setSignInOpen,
    setWalletOpen,
    setSelector,
    setBcdModal,
    setSpinWheel,
    showTrends,
    setShowTrends,
    setValue,
  } = useContext(modalsControl);
  const games = [
    {
      img: "crash",
      name: t("header.home.crash"),
      game: "crash",
    },
    {
      img: "limbo",
      name: t("header.home.limbo"),
      game: "limbo",
    },
    {
      img: "plinko",
      name: t("header.home.plinko"),
      game: "plinko",
    },
    {
      img: "mines",
      name: t("header.home.mines"),
      game: "mines",
    },
    {
      img: "classic-dice",
      name: t("header.home.classicDice"),
      game: "classic-dice",
    },
    {
      img: "keno",
      name: t("header.home.keno"),
      game: "keno",
    },
    {
      img: "hash-dice",
      name: t("header.home.hashDice"),
      game: "hash-dice",
    },
  ];

  const routeHeroSection = () => {
    setSignUpOpen(true);
  };
  const handleDeposit = () => {
    setWalletOpen(true);
  };
  const handlePlayGame = (e) => {
    const { name } = e.target;
    navigate(`/casino/${name}`);
  };
  const handleBcwClaim = () => {
    if (!currentUser) {
      setSignInOpen(true);
    }
    setSelector(true);
    setBcdModal(true);
  };
  const handleSpinOpen = () => {
    if (!currentUser) {
      setSignInOpen(true);
    }
    setSpinWheel(true);
  };
  const handleSwapOpen = () => {
    if (!currentUser) {
      setSignInOpen(true);
    }
    setWalletOpen(true);
    setValue(3);
  };
  const bcwClaim = () => {
    navigate("/referal-management/referal-dashboard");
  };

  const bannerOne = () => {
    if (!currentUser) {
      routeHeroSection();
    } else {
      handleSpinOpen();
    }
  };
  const bannerTwo = () => {
    if (!currentUser) {
      routeHeroSection();
    } else {
      handleDeposit();
    }
  };
  const bannerThree = () => {
    if (!currentUser) {
      routeHeroSection();
    } else {
      handleDeposit();
    }
  };

  useEffect(() => {
    if (showTrends) {
      setShowTrends(false);
    }
  }, []);
  return (
    <div className="main-wrapper-container">
      <div className="home-main-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="home-main-banner-text">
                <h2>
                  <span>{t("header.home.Leading")} </span>
                  {t("header.home.cryptogaming")}
                </h2>
                <p>{t("header.home.cryptogames")}</p>
                <ShowComponent condition={!currentUser}>
                  <button
                    className="primary-btn primary-btn-bg d-inline-block"
                    onClick={routeHeroSection}
                  >
                    {t("header.signup.signup")}
                  </button>
                </ShowComponent>
                <ShowComponent condition={currentUser}>
                  <button
                    className="primary-btn primary-btn-bg d-inline-block"
                    onClick={handleDeposit}
                  >
                    {t("header.wallet.deposit")}
                  </button>
                </ShowComponent>
              </div>
            </div>
            <div className="col-lg-8">
              <img
                src="/assets/home/images/main-banner-img.png"
                className="width-auto m-auto d-block"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="home-page-slider">
        <Slider {...settings}>
          <div className="pointer" onClick={bannerOne}>
            <img
              src={t("header.home.sliderimgone")}
              className="m-auto d-block hidden-xs-down"
              alt=""
            />
            <img
              src={t("header.home.sliderimgonemobile")}
              className="m-auto d-block hidden-sm-up"
              alt=""
            />
          </div>

          <div className="pointer" onClick={bannerTwo}>
            <img
              src={t("header.home.sliderimgtwo")}
              className="m-auto d-block hidden-xs-down"
              alt=""
            />
            <img
              src={t("header.home.sliderimgtwomobile")}
              className="m-auto d-block hidden-sm-up"
              alt=""
            />
          </div>

          <div className="pointer" onClick={bannerThree}>
            <img
              src={t("header.home.sliderimgthree")}
              className="m-auto d-block hidden-xs-down"
              alt=""
            />
            <img
              src={t("header.home.sliderimgthreemobile")}
              className="m-auto d-block hidden-sm-up"
              alt=""
            />
          </div>
        </Slider>
      </div>
      <div className="home-page-hot-games">
        <h2>
          <img
            src="/assets/home/svg/hot-icon.svg"
            className="width-auto"
            alt=""
          />
          {t("header.home.hotgames")}
        </h2>
        <div className="home-page-hot-games-box">
          <ul>
            {games.map((game) => (
              <li>
                <img
                  src={`/assets/home/images/hot-games/${game.img}.webp`}
                  className="width-auto"
                  alt={game.game}
                />
                <h1>{game.name}</h1>
                <button
                  name={game.game}
                  className="primary-btn primary-btn-bg"
                  onClick={handlePlayGame}
                >
                  {t("header.home.playnow")}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="bcw-perks">
        <div className="row">
          <div className="col-lg-5">
            <div
              className="position-relative mb-3 pointer perks-box"
              onClick={handleBcwClaim}
            >
              <p>
                <span>1 BCW = 1 USDT</span> {t("header.home.claim")}
              </p>
              <img
                src="/assets/home/images/perks-arrow.svg"
                className="perks-arrow"
                alt=""
              />
              <img
                src="/assets/home/images/bcw-perks-1.png"
                className="radius-7"
                alt=""
              />
            </div>
          </div>

          <div className="col-lg-7">
            <div className="row">
              <div className="col-lg-6" onClick={bcwClaim}>
                <div className="position-relative mb-3 pointer perks-box">
                  <p>
                    {t("header.home.Unlimiteddaily")}
                    <span> {t("header.home.rewards")}</span>
                  </p>
                  <img
                    src="/assets/home/images/perks-arrow.svg"
                    className="perks-arrow"
                    alt=""
                  />
                  <img
                    src="/assets/home/images/bcw-perks-2.png"
                    className="radius-7"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-lg-6" onClick={handleSwapOpen}>
                <div className="position-relative mb-3 pointer perks-box">
                  <p>
                    {t("header.home.EASY")}
                    <span> {t("header.wallet.swap")} </span>
                    {t("header.home.EASY")}
                    <span> {t("header.home.MONEY")} </span>
                  </p>
                  <img
                    src="/assets/home/images/perks-arrow.svg"
                    className="perks-arrow"
                    alt=""
                  />
                  <img
                    src="/assets/home/images/bcw-perks-3.png"
                    className="radius-7"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-lg-6" onClick={handleSpinOpen}>
                <div className="position-relative mb-3 pointer perks-box">
                  <p>
                    {t("header.signup.win")}
                    <span>{t("header.home.RealCrypto")} </span>
                    {t("header.home.byFreePlay")}
                  </p>
                  <img
                    src="/assets/home/images/perks-arrow.svg"
                    className="perks-arrow"
                    alt=""
                  />
                  <img
                    src="/assets/home/images/bcw-perks-4.png"
                    className="radius-7"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="position-relative mb-3 pointer perks-box">
                  <p>
                    {t("header.home.AntiPhishing")}
                    <span> {t("header.home.AwarEness")}</span>
                  </p>
                  <img
                    src="/assets/home/images/perks-arrow.svg"
                    className="perks-arrow"
                    alt=""
                  />
                  <img
                    src="/assets/home/images/bcw-perks-5.png"
                    className="radius-7"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-before-footer-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="home-before-footer-banner-text">
                <h2 className="mb-3">
                  <span> {t("header.home.Registered")} </span>
                  {t("header.home.instantlyredeem")}
                  <span> 300% </span>
                  {t("header.home.depositbonus")}
                </h2>

                <ShowComponent condition={!currentUser}>
                  <button
                    className="primary-btn primary-btn-bg d-inline-block"
                    onClick={routeHeroSection}
                  >
                    {t("header.signup.signup")}
                  </button>
                </ShowComponent>
                <ShowComponent condition={currentUser}>
                  <button
                    className="primary-btn primary-btn-bg d-inline-block"
                    onClick={handleDeposit}
                  >
                    {t("header.wallet.deposit")}
                  </button>
                </ShowComponent>
              </div>
            </div>
            <div className="col-md-8">
              <img
                src="/assets/home/images/home-before-footer.png"
                className="width-auto m-auto d-block"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
