import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "500px",
  backgroundColor: "#000",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

const VerifyResult = ({ setOpenModal, betData }) => {
  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              padding: "20px",
            }}
            className="bet-user-modal-top my_row space-between"
          >
            <span className="orbitron-font">Verify Result</span>
            <span>
              <button className="white" onClick={handleClose}>
                <CloseIcon />
              </button>
            </span>
          </div>
          <div style={{ maxHeight: "500px", overflowY: "auto" }}>
            <div id="app" className="main">
              <h1 className="text-center mb-3"></h1>
              <form className="">
                <h2 className="text-center">Input</h2>
                <div className="form-group mb-3">
                  <input
                    placeholder={betData.server_seed}
                    className="form-control"
                  />
                </div>
                <div className="form-group mb-3">
                  <input
                    placeholder={betData.client_seed}
                    className="form-control"
                  />
                </div>
                <div className="form-group mb-3">
                  <input placeholder={betData.nonce} className="form-control" />
                </div>
              </form>
              <form className="">
                <h2 className="text-center mb-3">Output</h2>
                <div className="form-group mb-3">
                  <label className="mb-2 d-inline-block">
                    sha256(server_seed)
                  </label>
                  <input
                    placeholder={betData.server_seed}
                    className="form-control"
                  />
                </div>
                <div className="form-group mb-3">
                  <label className="mb-2 d-inline-block">
                    hmac_sha256(client_seed:nonce, server_seed)
                  </label>
                  <input
                    placeholder={betData.hmac_sha256}
                    className="form-control"
                  />
                  {console.log("here is the array tiles_array", betData)}
                </div>
              </form>
              <div className="">
                <h5 className="mb-3 d-inline-block">Final Result</h5>
              </div>
              <ul className="mines-results-users my_row">
                {betData.tiles_array?.map((item,index) => (
                  <li className={`bold ${
                    item.type ==="gem" ? "bg-success" : "bg-danger"
                  }`}> {item.id} 
                  </li>
                ))}
          
              </ul>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
export default VerifyResult;
