import "./Common.css";
import { useState, useContext, memo } from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import Crash from "../Games/Crash/Crash";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setConversionRates } from "../../store/game";
import Spinner from "../Common/Modals/Spinner";
import SpinSignup from "./Modals/SignupSpin";
import UserFeedback from "./UserFeedback";
import ConversionRatesAPI from "../../api/conversion_rate";
import { betPriceContext } from "../Contexts/BetPrice";
import useWindowDimensions from "../Contexts/windowDimensions";
import { modalsControl } from "../Contexts/ModalsControl";
import Alert from "./Alert";
import ResetPassword from "./Modals/ResetPassword";
import VerifyEmail from "./Modals/VerifyEmail";
import MobileTrendsOpener from "./Additional/MobileTrendsOpener";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import ReferalBeforeLogin from "../Pages/ReferalBeforeLogin";
import ReferralManagement from "./ReferralManagement";
import Chat from "./Chat/Chat";
import SideBar from "./Sidebar/SideBar";
import MenuIcon from "@mui/icons-material/Menu";
import LanguageIcon from "@mui/icons-material/Language";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Languages from "./Languages/Languages";

import Undermaintenance from "../Common/Undermaintenance";

import Limbo from "../Games/Limbo/Limbo";
import Plinko from "../Games/Plinko/Plinko";
import Mines from "../Games/Mines/Mines";
import ClassicDice from "../Games/ClassicDice/ClassicDice";
import Keno from "../Games/Keno/Keno";
import HashDice from "../Games/HashDice/HashDice";

import Home from "../Pages/Home";

import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import RemoveIcon from "@mui/icons-material/Remove";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ShowComponent from "./ShowComponent/ShowComponent";
import QuestModal from "./Quest/Quest";

import VIPClub from "./Modals/VIPClub/VIPClub";

const Common = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.currentUser);

  // const game_state = useSelector((state) => state.game.game_state);

  const { insuficientWallet } = useContext(betPriceContext);
  const {
    spinWheel,
    setReferalLinkModal,
    setSignInOpen,
    setSpinWheel,
    setGettingRate,
    liveSupport,
    setLiveSupport,
    showTrends,
    questModal,
    setQuestModal,
    setVipClub,
    VIPClubModal,
    activeLiveSupport,
  } = useContext(modalsControl);

  const { t, i18n } = useTranslation();
  const { height, width } = useWindowDimensions();
  const navigate = useNavigate();

  const [second, setSecond] = useState(0);

  useEffect(() => {
    if (second >= 180) {
      setSecond(0);
    } else {
      setTimeout(() => {
        setSecond(second + 1);
      }, 1000);
    }
  }, [second]);

  let [once, setOnce] = useState(true);

  useEffect(() => {
    if (once || second > 179) {
      setGettingRate(true);
      ConversionRatesAPI.get_conversion_rates().then((response) => {
        setGettingRate(false);
        dispatch(
          setConversionRates({
            conversion_rates: response.data,
          })
        );
      });
      setOnce(false);
    }
  }, [currentUser, second]);

  const [sidebarBox, setsidebarBox] = useState(false);
  const opensidebarBox = () => {
    sidebarBox ? setsidebarBox(false) : setsidebarBox(true);
  };
  const [showPromotions, setshowPromotions] = useState(false);

  const [show, setShow] = useState(false);
  const [chatBox, setChatBox] = useState(false);
  const [languageSelector, setLanguageSelector] = useState(false);
  const handleLanguageSelector = () => {
    setLanguageSelector(!languageSelector);
  };
  const openChatBox = () => {
    chatBox ? setChatBox(false) : setChatBox(true);
  };
  const handleReferEarn = () => {
    if (!currentUser) {
      setSignInOpen(true);
      return;
    }
    navigate("/referal-management/referal-dashboard");
    setReferalLinkModal(true);
  };
  const handleNavigate = () => {
    navigate("/");
    setsidebarBox(false);
  };
  const handleSpinOpen = () => {
    if (!currentUser) {
      setSignInOpen(true);
      return;
    }
    setSpinWheel(true);
  };
  const handleLiveSupport = () => {
    setLiveSupport(!liveSupport);
  };
  const handleVip = () => {
    setVipClub(true);
  };
  const handleQuest = () => {
    setQuestModal(true);
  };
  useEffect(() => {
    if (window.location.pathname === "/crash") {
      navigate("/casino/crash");
    }
  }, []);
  return (
    <div
      style={{
        overflow: "none",
      }}
      className="top-level-parent relative my_row"
    >
      <div
        className={`sideBar-container width-5 fixed height ${
          sidebarBox ? "hidden-bar mobile-hidden-bar" : "shown-bar"
        } ${chatBox && "mobile-hidden-bar"} `}
      >
        <SideBar
          chatBox={chatBox}
          setChatBox={setChatBox}
          setsidebarBox={setsidebarBox}
          sidebarBox={sidebarBox}
          setLanguageSelector={setLanguageSelector}
          handleReferEarn={handleReferEarn}
          handleSpinOpen={handleSpinOpen}
        />
      </div>
      <div
        style={{
          position: "relative",
          overflow: "auto",
        }}
        className="top-level-div width-95 height"
      >
        <div
          style={{
            position: "sticky",
            top: "0",
            zIndex: "20",
          }}
          className="common-header-container"
        >
          <VerifyEmail />
          <ResetPassword />

          {spinWheel && <Spinner />}

          <ShowComponent condition={VIPClubModal}>
            <VIPClub />
          </ShowComponent>

          {!currentUser && <SpinSignup />}

          <ShowComponent condition={questModal}>
            <QuestModal />
          </ShowComponent>

          <Header
            onClick={openChatBox}
            sidebaropen={opensidebarBox}
            handleReferEarn={handleReferEarn}
          />
        </div>
        <div
          style={{
            transition: ".5s",
          }}
          className={`common-component-main-div justified ${
            chatBox && width >= 767
              ? "width"
              : chatBox && width <= 767
              ? "none"
              : "width"
          }`}
        >
          <div className="common-component-inner-div page-width">
            <div className="common-component-content-div relative">
              <div
                style={{
                  position: "fixed",
                  zIndex: "22",
                  left: "0",
                  background: "orange",
                  opacity: insuficientWallet ? "100%" : "0%",
                  left: insuficientWallet ? "0px" : "-375px",
                  transition: ".3s",
                }}
                className="insuficient-amount-alert-container"
              >
                <Alert
                  type="warning"
                  message="Insuficient balance - Please recharge your wallet"
                />
              </div>

              <ShowComponent condition={showTrends}>
                <div
                  className="trends-chat-on-mobile"
                  style={{
                    display: "none",
                  }}
                >
                  <MobileTrendsOpener />
                </div>
              </ShowComponent>

              {/* Common routing elements  */}
              <div className="common-game-container">
                <Routes>
                  <Route
                    // path="/game/crash"
                    path="/casino/crash"
                    element={<Crash chatBox={chatBox} />}
                  ></Route>

                  <Route
                    // path="/game/crash"
                    path="/"
                    element={<Home chatBox={chatBox} />}
                  ></Route>

                  <Route
                    // path="/game/crash"
                    path="/undermaintenance"
                    element={<Undermaintenance chatBox={chatBox} />}
                  ></Route>

                  <Route
                    // path="/game/crash"
                    path="/casino/limbo"
                    element={<Limbo chatBox={chatBox} />}
                  ></Route>

                  <Route
                    // path="/game/crash"
                    path="/casino/mines"
                    element={<Mines chatBox={chatBox} />}
                  ></Route>

                  <Route
                    // path="/game/crash"

                    path="/casino/classic-dice"
                    element={<ClassicDice chatBox={chatBox} />}
                  ></Route>

                  <Route
                    // path="/game/crash"

                    path="/casino/hash-dice"
                    element={<HashDice chatBox={chatBox} />}
                  ></Route>

                  <Route
                    // path="/game/crash"
                    path="/casino/keno"
                    element={<Keno chatBox={chatBox} />}
                  ></Route>

                  <Route
                    // path="/game/crash"
                    path="/casino/plinko"
                    element={<Plinko chatBox={chatBox} />}
                  ></Route>

                  <Route path="/casino/crash" element={<Crash />}></Route>
                  <Route
                    path="/referal-management/:pathname"
                    element={
                      currentUser ? (
                        <ReferralManagement />
                      ) : (
                        <ReferalBeforeLogin />
                      )
                    }
                  ></Route>

                  {/* <Route path="/" element={<ReferalBeforeLogin />}></Route> */}
                </Routes>
              </div>
              {/* Common routing ends here  */}

              {/* Common footer starts here  */}
              <div className="user-feedBack-section">
                <div
                  style={{
                    marginBottom: "30px",
                    background: "#1c1e22",
                    borderRadius: "7px",
                  }}
                  className="userFeedbackInner-container"
                >
                  <UserFeedback />
                </div>
              </div>
              
              {/* Common footer ends here */}
            </div>
          </div>
        </div>
        <Footer />
        <div
          className="chat-main-box"
          style={{
            width:
              chatBox && width >= 767
                ? "350px"
                : chatBox && width <= 767
                ? "100%"
                : "0%",
            opacity: chatBox ? "100%" : "0",
            position: "fixed",
            top: "68px",
            right: chatBox ? "0px" : "-272px",
            transition: ".3s",
            zIndex: "99",
            height: "100vh",
            background: "#24262b",
          }}
        >
          {chatBox && <Chat openChatBox={openChatBox} />}
        </div>
        <div
          className={
            sidebarBox === true
              ? "affiliate-side-pannel"
              : "affiliate-side-pannel"
          }
          style={{
            width:
              sidebarBox && width >= 767
                ? "270px"
                : sidebarBox && width <= 767
                ? "270px"
                : "0%",
            opacity: sidebarBox ? "100%" : "0",
            position: "fixed",
            top: "0",
            left: sidebarBox ? "0px" : "-272px",
            transition: ".3s",
            zIndex: "99",
            height: "100vh",
          }}
        >
          <div
            style={{
              backgroundColor: "#101010",
              border: ".5px solid #2d9fc659",
              zIndex: "1",
            }}
            className={`side-bar-languages-container width absolute ${
              languageSelector
                ? "shown-language-selector"
                : "hidden-language-selector"
            }`}
          >
            <div
              style={{
                borderBottom: "1px solid #2d9fc659",
                padding: "15px",
                backgroundColor: "#191a1d",
              }}
              className="language-top-container width centered_text"
            >
              <span className="white bold">Languages</span>
            </div>

            <div className="side-bar-languages width white">
              <Languages setLanguageSelector={setLanguageSelector} />
            </div>
          </div>
          <div
            className="logo-hamburger-container pointer my_row center_aligns space-between"
            style={{
              background: "#2b2d34",
              margin: "-10px -10px 10px",
              padding: "17px 10px 16px 10px",
            }}
          >
            <div className="side-bar-open-icon-container justified">
              <button
                className="text_color sidebar-action-button"
                style={{ background: "none" }}
                onClick={opensidebarBox}
              >
                <img
                  src="/imgs/sidebar/sidebar-swipe-icon.svg"
                  className="width-auto"
                  alt=""
                />
              </button>
            </div>
            <div className="sideBarMenu-logo-container width-75">
              <img src="/imgs/logo/logo.png" alt="" onClick={handleNavigate} />
            </div>
          </div>
          <div className="affiliate-side-pannel-content">
            <div
              style={{
                padding: "8px 10px",
              }}
              className="side-bar-expand-spin-container pointer dark-bg-one radius-4"
              onClick={handleSpinOpen}
            >
              <div className="side-bar-expand-spin-top-container width hidden">
                <span className="bold" style={{ color: "#FFBF39" }}>
                  {t("header.referral.vip")}
                </span>
              </div>
              <div className="expanded-spin-time-container my_row">
                <div className="expanded-spin-img-container width-20 my_row center_align">
                  <img src="/imgs/sidebar/infiniteSpin.webp" alt="Spin Wheel" />
                </div>
                <div className="expanded-spin-time-container width-80">
                  <div
                    className="next-spin-time width"
                    style={{ lineHeight: "14px" }}
                  >
                    <span className="bold fs-12 white">
                      {t("header.referral.earn")}
                    </span>
                  </div>
                  <div
                    className="next-spin-text width"
                    style={{ lineHeight: "14px" }}
                  >
                    <span
                      style={{
                        fontSize: "10px",
                      }}
                      className="width text_color"
                    >
                      {t("header.referral.free")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex gap-2 pointer" style={{ marginTop: "10px" }}>
              <div
                className="dark-bg-one w-100 text-center py-2 radius-4"
                style={{
                  background:
                    "linear-gradient(180deg, #1C1E22 0%, #69C9B9 100%)",
                }}
                onClick={handleQuest}
              >
                <img
                  src="/imgs/sidebar/quest.webp"
                  className="width-auto"
                  style={{ maxHeight: "35px" }}
                  alt=""
                />
                <p className="fs-12 mt-2">Quest</p>
              </div>
              <div
                className="dark-bg-one w-100 text-center py-2 radius-4 pointer"
                style={{
                  background:
                    "linear-gradient(0deg, #DA0F15 0%, #1C1E22 98.96%)",
                }}
                onClick={handleVip}
              >
                <img
                  src="/imgs/sidebar/vip.webp"
                  className="width-auto"
                  style={{ maxHeight: "35px" }}
                  alt=""
                />
                <p className="fs-12 mt-2">VIP Club</p>
              </div>
            </div>
            <div
              style={{
                marginTop: "10px",
              }}
              className="refer-&-earn-side-bar-menu pointer"
            >
              <button
                className="refer-earn-img-text-container width my_row center_align pointer dark-bg-one radius-4"
                onClick={handleReferEarn}
              >
                <img
                  style={{
                    maxWidth: "27px",
                    margin: "6px",
                  }}
                  src="/imgs/sidebar/refer.webp"
                  alt="refer & earn"
                />
                <span className="white fs-15" style={{ color: "#9c9c9c" }}>
                  {t("header.referral.rerearn")}
                </span>
              </button>
            </div>

            <div className="affiliate-accordion js-acc-single">
              <div
                className={`affiliate-accordion-item js-acc-item ${
                  show && "is-open"
                }`}
                style={{
                  borderTop: "solid 1px #2B2B2D",
                  borderBottom: "solid 1px #2B2B2D",
                  padding: "15px 0",
                  margin: "20px 0",
                }}
              >
                <h2
                  onClick={() => setShow(!show)}
                  className="affiliate-accordion-title js-acc-single-trigger"
                >
                  <img
                    src="/imgs/sidebar/hot-icon.svg"
                    alt="casino"
                    style={{ maxWidth: "18px", maxHeight: "18px" }}
                  />
                  &nbsp; {t("header.home.hotgames")}
                </h2>
                {show ? (
                  <div className="affiliate-accordion-content">
                    <ul className="affiliate-left-pannel-menu sidebar-hot-games-list">
                      <li>
                        <button onClick={opensidebarBox}>
                          <Link to="/casino/crash">
                            <img
                              src="/imgs/sidebar/game-logos/crash.webp"
                              alt=""
                              className="width-auto"
                            />
                            {t("header.home.crash")}
                          </Link>
                        </button>
                      </li>
                      <li>
                        <button onClick={opensidebarBox}>
                          <Link to="/casino/limbo">
                            <img
                              src="/imgs/sidebar/game-logos/limbo.webp"
                              alt=""
                              className="width-auto"
                            />
                            {t("header.home.limbo")}
                          </Link>
                        </button>
                      </li>
                      <li>
                        <button onClick={opensidebarBox}>
                          <Link to="/casino/plinko">
                            <img
                              src="/imgs/sidebar/game-logos/plinko.webp"
                              alt=""
                              className="width-auto"
                            />
                            {t("header.home.plinko")}
                          </Link>
                        </button>
                      </li>
                      <li>
                        <button onClick={opensidebarBox}>
                          <Link to="/casino/mines">
                            <img
                              src="/imgs/sidebar/game-logos/mines.webp"
                              alt=""
                              className="width-auto"
                            />
                            {t("header.home.mines")}
                          </Link>
                        </button>
                      </li>
                      <li>
                        <button onClick={opensidebarBox}>
                          <Link to="/casino/classic-dice">
                            <img
                              src="/imgs/sidebar/game-logos/classic-dice.webp"
                              alt=""
                              className="width-auto"
                            />
                            {t("header.home.classicDice")}
                          </Link>
                        </button>
                      </li>
                      <li>
                        <button onClick={opensidebarBox}>
                          <Link to="/casino/keno">
                            <img
                              src="/imgs/sidebar/game-logos/keno.webp"
                              alt=""
                              className="width-auto"
                            />
                            {t("header.home.keno")}
                          </Link>
                        </button>
                      </li>
                      <li>
                        <button onClick={opensidebarBox}>
                          <Link to="/casino/hash-dice">
                            <img
                              src="/imgs/sidebar/game-logos/hash-dice.webp"
                              alt=""
                              className="width-auto"
                            />
                            {t("header.home.hashDice")}
                          </Link>
                        </button>
                      </li>
                    </ul>
                  </div>
                ) : null}
              </div>
              <div
                className={`affiliate-accordion-item js-acc-item ${
                  showPromotions && "is-open"
                }`}
              >
                <h2
                  className="affiliate-accordion-title js-acc-single-trigger"
                  onClick={() => setshowPromotions(!showPromotions)}
                >
                  <img
                    src="/imgs/sidebar/promotions.svg"
                    alt="Promotions"
                    style={{ maxWidth: "18px", maxHeight: "18px" }}
                  />
                  &nbsp; {t("header.referral.Promo")}
                </h2>
                {showPromotions ? (
                  <div className="affiliate-accordion-content">
                    <ul className="affiliate-left-pannel-menu">
                      <li>
                        <button onClick={opensidebarBox}>
                          <Link to="/referal-management/referal-dashboard">
                            <img
                              src="/imgs/sidebar/affiliate-icon.svg"
                              alt=""
                              className="width-auto"
                            />
                            {t("header.referral.Affiliate")}
                          </Link>
                        </button>
                      </li>
                      {/* <li>
                      <a href="#">
                        <img
                          src="/imgs/blogs-icon.svg"
                          alt=""
                          className="width-auto"
                        />
                        Blogs
                      </a>
                    </li> */}
                    </ul>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="side-bar-language-selector-main">
              <div className="d-flex gap-2">
                <div className="side-bar-language-selector-inner w-50">
                  <button
                    style={{
                      padding: "8px",
                      color: "#9c9c9c",
                    }}
                    className="side-bar-language-selector-content width justified white dark-bg-one radius-4"
                    onClick={handleLanguageSelector}
                  >
                    <div className="language-selector-icon-container text_color">
                      <LanguageIcon />
                    </div>
                    <div
                      style={{
                        margin: "0px 0px 0px 4px",
                        borderRadius: "5px",
                      }}
                      className="language-selector-name-container"
                    >
                      {i18n.resolvedLanguage}
                    </div>
                    <div
                      className={`language-selector-arrow-container text_color ${
                        languageSelector && "rotate-icon"
                      }`}
                    >
                      <KeyboardArrowDownIcon />
                    </div>
                  </button>
                </div>
                <div className="white w-50">
                  <button
                    style={{
                      padding: "8px",
                      color: "#9c9c9c",
                    }}
                    className="side-bar-language-selector-content width justified white dark-bg-one radius-4 w-100"
                    onClick={activeLiveSupport}
                  >
                    <img
                      src="/imgs/sidebar/chat.webp"
                      style={{
                        height: "24px",
                        width: "auto",
                        marginRight: "10px",
                      }}
                      alt=""
                    />
                    Support
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="live-support-main fixed">
        <div className="live-support-inner">
          <div className="live-support-button-container">
            {liveSupport ? (
              <div className="live-support-chat-box-main">
                <div className="live-support-inner-box">
                  <div className="live-support-content-box">
                    <div className="live-support-header width my_row space-between white">
                      <span className="bold live-support">Live Support</span>
                      <button
                        className="white bold"
                        onClick={handleLiveSupport}
                      >
                        <RemoveIcon />
                      </button>
                    </div>
                    <div className="live-support-body width height justified" style={{ flexWrap: "wrap", justifyContent: "start" }}>
                      <a
                        href="https://api.whatsapp.com/message/PHAZTK3A43KDE1?autoload=1&app_absent=0"
                        className="width-50 gc justified column"
                        target="_blank"
                      >
                        <WhatsAppIcon className="width" />
                        <span>Indira</span>
                      </a>
                      <a
                        className="width-50 gc justified column"
                        href="https://api.whatsapp.com/message/JETL6LRAY2C7P1?autoload=1&app_absent=0"
                        target="_blank"
                      >
                        <WhatsAppIcon />
                        <span>Ansuya Ki</span>
                      </a>
                      <a
                        className="width-50 gc justified column"
                        href="https://line.me/ti/p/9wXtMd2o4T"
                        target="_blank"
                      >
                        <img src="/imgs/line-icon.png" alt="" style={{ width: "24px" }} />
                        <span>@bcwjanis</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <button
                className="live-support-button white"
                onClick={handleLiveSupport}
              >
                <HeadsetMicIcon />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Common);
