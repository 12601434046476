import "./Limbo.css";
import React, { useState, useEffect, useContext, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import useSound from "use-sound";

import WinSound from "./Addons/Sound/win.mp3";
import loseSound from "./Addons/Sound/lose.mp3";

// Splitting components
import Graph from "./Splitting/Graph";
import PlayArea from "./Splitting/PlayArea";
import BetStats from "./Splitting/Stats/Stats";
import MaxBetAlert from "../../Common/MaxBetAlert/MaxBetAlert";

// Contexts import
import { modalsControl } from "../../Contexts/ModalsControl";
import { betPriceContext } from "../../Contexts/BetPrice";
// Redux state setter imports
import { setUserWallets } from "../../../store/user";
// API imports
import BetConfigration from "../../../api/currency_bet_configrations";
import UserWalletsAPI from "../../../api/user_wallets";
import LimboAPis from "../../../api/Limbo/limbo";
import PoolAPI from "../../../api/pool";
import ShowComponent from "../../Common/ShowComponent/ShowComponent";

const Limbo = () => {
  const [RocketSound] = useSound(WinSound);
  const [RocketloseSound] = useSound(loseSound);
  // Redux states
  const dispatch = useDispatch();
  const user_id = useSelector((state) => state.user.currentUser?.user_id);
  // Context states
  const { setSignInOpen, showTrends, setShowTrends } =
    useContext(modalsControl);

  const { activatedCurrency } = useContext(betPriceContext);
  // Helper Variables
  const inputInitials = {
    betAmount: 1,
    betPayout: 1.98,
    autoBets: 0,
    increaseOnWin: 0,
    decreaseOnLoss: 0,
    autoProfitStop: 0,
    stopProfit: 0,
    stopLoss: 0,
  };
  // Controlled states
  const [boostedRocket, setBoostedRocket] = useState(false);
  const [inProgressBet, setInprogressBet] = useState(false);
  const [autoBetInProgress, setAutoBetInProgress] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [multiplierNumber, setMultiplierNumber] = useState("1.00");
  const [incrementNumber, setIncrementNumber] = useState(null);
  const [decrementNumber, setDecrementNumber] = useState(null);
  const [placed, setPlaced] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [winStatus, setWinStatus] = useState(null);
  const [blast, setBlast] = useState(false);
  const [myBets, setMyBets] = useState([]);
  const [pool_amounts, setPoolAmounts] = useState([]);
  const [selectedPool, setSelectedPool] = useState([]);
  const [receivedMultiplier, setReceivedMultiplier] = useState(0);
  const [betValues, setBetValues] = useState(inputInitials);
  const [currencyConfigrations, setCurrencyConfigrations] = useState([]);
  const [autoPlacedBets, setAutoPlacedBets] = useState(0);
  const [calculatedAutoProfit, setCalculatedAutoProfit] = useState(0);
  const [calculatedAutoLoss, setCalculatedAutoLoss] = useState(0);
  const [onWinMode, setOnWinMod] = useState("reset");
  const [onLossMode, setOnLossMode] = useState("reset");
  const [betAmountToReset, setBetAmountToReset] = useState(0);
  const [maxBetAlert, setMaxBetAlert] = useState(false);
  const [dontShowAlert, setDontShowAlert] = useState(false);
  const [maxProfitApplied, setMaxProfitApplied] = useState(false);
  // Bet Associated States
  const [configuredCurrency, setConfiguredCurrency] = useState({});
  // Helper functions
  const findConfiguredCurrency = () => {
    setConfiguredCurrency(
      currencyConfigrations?.find(
        (currency) => currency.network_id === activatedCurrency.network_id
      )
    );
  };
  const fetchPoolAmounts = () => {
    PoolAPI.getPoolAmounts("limbo").then((response) => {
      setPoolAmounts(response?.data?.pool_values);
    });
  };
  const fetchUserWallets = () => {
    UserWalletsAPI.getUserWallets().then((response) => {
      dispatch(
        setUserWallets({
          wallets: response.data,
        })
      );
    });
  };
  const fetchBetsHistory = () => {
    if (user_id) {
      setIsLoading(true);
      LimboAPis.my_bets()
        .then((response) => {
          setMyBets(response.data.data.user_bets);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  // Effects
  // Bet Configration setter
  useEffect(() => {
    BetConfigration.bet_configrations("limbo")
      .then((res) => {
        setCurrencyConfigrations(res.data.currency_configuration);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    findConfiguredCurrency();
  }, [currencyConfigrations.length, activatedCurrency.network_id]);

  useEffect(() => {
    if (!placed) {
      return;
    }
    if (parseFloat(receivedMultiplier) > parseFloat(multiplierNumber)) {
      if (
        parseFloat(multiplierNumber) + parseFloat(incrementNumber) <
        parseFloat(receivedMultiplier)
      ) {
        const interval = setTimeout(() => {
          setMultiplierNumber(
            parseFloat(multiplierNumber) + parseFloat(incrementNumber)
          );
        }, 90);
      } else {
        setMultiplierNumber(receivedMultiplier);
      }
    }
  }, [incrementNumber, multiplierNumber]);

  useEffect(() => {
    if (!placed) {
      return;
    }
    if (parseFloat(receivedMultiplier) < parseFloat(multiplierNumber)) {
      if (
        parseFloat(multiplierNumber) - parseFloat(decrementNumber) >
        parseFloat(receivedMultiplier)
      ) {
        const interval = setTimeout(() => {
          setMultiplierNumber(
            parseFloat(multiplierNumber) - parseFloat(decrementNumber)
          );
        }, 90);
      } else {
        setMultiplierNumber(receivedMultiplier);
      }
    }
  }, [decrementNumber, multiplierNumber]);

  const handleAutoBet = () => {
    setBetAmountToReset(betValues.betAmount);
    setAutoBetInProgress(!autoBetInProgress);
  };

  const handleBetPlace = () => {
    if (
      parseFloat(betValues.betAmount) >=
        parseFloat(configuredCurrency.max_bet) &&
      !maxBetAlert &&
      !dontShowAlert
    ) {
      if (!dontShowAlert) {
        setMaxBetAlert(true);
      }
    } else {
      if (maxBetAlert) {
        setMaxBetAlert(false);
      }
      if (!user_id) {
        setSignInOpen(true);
        return;
      }
      if (inProgressBet) {
        return;
      }
      setInprogressBet(true);
      LimboAPis.create_bet({
        limbo: {
          amount: betValues.betAmount,
          cash_out: betValues.betPayout,
          network_id: activatedCurrency.network_id,
        },
      })
        .then((res) => {
          setPlaced(true);
          if (res.data.data.multiplier_no > parseFloat(multiplierNumber)) {
            setIncrementNumber(parseFloat(res.data.data.multiplier_no) / 8);
          }
          if (res.data.data.multiplier_no < parseFloat(multiplierNumber)) {
            setDecrementNumber(
              (parseFloat(multiplierNumber) - res.data.data.multiplier_no) / 8
            );
          }

          setReceivedMultiplier(res.data.data.multiplier_no);
          setBoostedRocket(true);
          setTimeout(() => {
            setBlast(true);
          }, 130);
          setWinStatus(res.data.data.win_status);

          if (res.data.data.win_status) {
            RocketSound();
            setMaxProfitApplied(res.data.data.max_profit_applied);

            setCalculatedAutoProfit(
              calculatedAutoProfit + parseFloat(res.data.data.profit)
            );
            let percentage =
              (parseFloat(betValues.betAmount) / 100) *
              parseFloat(betValues.increaseOnWin);
            if (autoBetInProgress) {
              if (calculatedAutoLoss - parseFloat(res.data.data.profit) > 0) {
                setCalculatedAutoLoss(
                  calculatedAutoLoss - parseFloat(res.data.data.profit)
                );
              } else {
                setCalculatedAutoLoss(0);
              }

              if (onWinMode === "increase") {
                if (
                  parseFloat(betValues.betAmount) + parseFloat(percentage) >
                  parseFloat(configuredCurrency.max_bet)
                ) {
                  setBetValues({
                    ...betValues,
                    betAmount: parseFloat(configuredCurrency.max_bet).toFixed(
                      configuredCurrency?.precision
                    ),
                  });
                } else {
                  setBetValues({
                    ...betValues,
                    betAmount: (
                      parseFloat(betValues.betAmount) + percentage
                    ).toFixed(configuredCurrency?.precision),
                  });
                }
              } else {
                setBetValues({
                  ...betValues,
                  betAmount: parseFloat(betAmountToReset).toFixed(
                    configuredCurrency?.precision
                  ),
                });

                // alert("on win mod set to reset");
              }
            }
          } else {
            RocketloseSound();

            let percentage =
              (parseFloat(betValues.betAmount) / 100) *
              parseFloat(betValues.decreaseOnLoss);

            setCalculatedAutoLoss(
              calculatedAutoLoss + parseFloat(res.data.data.profit)
            );

            if (autoBetInProgress) {
              if (calculatedAutoProfit - parseFloat(res.data.data.profit) > 0) {
                setCalculatedAutoProfit(
                  calculatedAutoProfit - parseFloat(res.data.data.profit)
                );
              } else {
                setCalculatedAutoProfit(0);
              }

              if (onLossMode === "increase") {
                if (
                  parseFloat(betValues.betAmount) + parseFloat(percentage) >
                  parseFloat(configuredCurrency.max_bet)
                ) {
                  setBetValues({
                    ...betValues,
                    betAmount: parseFloat(configuredCurrency.max_bet).toFixed(
                      configuredCurrency?.precision
                    ),
                  });
                } else {
                  setBetValues({
                    ...betValues,
                    betAmount: (
                      parseFloat(betValues.betAmount) + percentage
                    ).toFixed(configuredCurrency?.precision),
                  });
                }
              } else {
                setBetValues({
                  ...betValues,
                  betAmount: parseFloat(betAmountToReset).toFixed(
                    configuredCurrency?.precision
                  ),
                });

                // alert("on win mod set to reset");
              }
            }
          }

          fetchBetsHistory();
          fetchPoolAmounts();
          fetchUserWallets();
          setTimeout(() => {
            setBoostedRocket(false);
            setInprogressBet(false);
            setBlast(false);
            setMaxProfitApplied(false);
            setAutoPlacedBets(autoPlacedBets + 1);
          }, 1000);
        })
        .catch((err) => {
          setInprogressBet(false);
          setAutoBetInProgress(false);
          setAlertMessage(err.response.data.message);
          setTimeout(() => {
            setAlertMessage("");
          }, 3000);
        });
    }
  };

  // Fetch my history & pool amounts
  useEffect(() => {
    fetchBetsHistory();
    fetchPoolAmounts();
  }, []);
  // Select the desired pool
  useEffect(() => {
    setSelectedPool(
      pool_amounts?.find(
        (element) => element.network_id === activatedCurrency?.network_id
      )
    );
  }, [activatedCurrency?.network_id, pool_amounts]);
  useEffect(() => {
    if (showTrends) {
      setShowTrends(false);
    }
  }, []);
  useEffect(() => {
    if (
      parseInt(autoPlacedBets) < parseInt(betValues.autoBets) &&
      autoBetInProgress &&
      parseInt(betValues.autoBets) - autoPlacedBets > 0
    ) {
      handleBetPlace();
    } else if (autoBetInProgress && parseInt(betValues.autoBets) == 0) {
      handleBetPlace();
    } else {
      setAutoBetInProgress(false);
      setAutoPlacedBets(0);
    }
  }, [autoPlacedBets, autoBetInProgress]);
  useEffect(() => {
    if (parseFloat(betValues.stopProfit) !== 0) {
      if (
        parseFloat(calculatedAutoProfit) >= parseFloat(betValues.stopProfit)
      ) {
        setAutoBetInProgress(false);
        setCalculatedAutoProfit(0);
      }
    }
    if (parseFloat(betValues.stopLoss) !== 0) {
      if (parseFloat(calculatedAutoLoss) >= parseFloat(betValues.stopLoss)) {
        setAutoBetInProgress(false);
        setCalculatedAutoLoss(0);
      }
    }
  }, [calculatedAutoProfit, calculatedAutoLoss]);
  useEffect(() => {
    document.title =
      "Limbo Game - Step into the intriguing world of Limbo, a thrilling game of strategy.";
  }, []);

  return (
    <div className="main-wrapper-container">
      <div className="row m-0">
        <ShowComponent condition={maxBetAlert}>
          <MaxBetAlert
            maxBetAlert={maxBetAlert}
            setMaxBetAlert={setMaxBetAlert}
            dontShowAlert={dontShowAlert}
            setDontShowAlert={setDontShowAlert}
            performAction={handleBetPlace}
            setAutoBetInProgress={setAutoBetInProgress}
            autoBetInProgress={autoBetInProgress}
            inProgressBet={inProgressBet}
            setInprogressBet={setInprogressBet}
          />
        </ShowComponent>

        <Graph
          rocketState={boostedRocket}
          multiplierNumber={multiplierNumber}
          selectedPool={selectedPool}
          activatedCurrency={activatedCurrency}
          winStatus={winStatus}
          myBets={myBets}
          fetchBetsHistory={fetchBetsHistory}
          inProgressBet={inProgressBet}
          blast={blast}
          maxProfitApplied={maxProfitApplied}
        />
        <PlayArea
          inProgressBet={inProgressBet}
          betPlacer={handleBetPlace}
          alertMessage={alertMessage}
          configuredCurrency={configuredCurrency}
          betValues={betValues}
          setBetValues={setBetValues}
          handleAutoBet={handleAutoBet}
          autoPlacedBets={autoPlacedBets}
          setAutoPlacedBets={setAutoPlacedBets}
          autoBetInProgress={autoBetInProgress}
          calculatedAutoProfit={calculatedAutoProfit}
          calculatedAutoLoss={calculatedAutoLoss}
          onWinMode={onWinMode}
          setOnWinMod={setOnWinMod}
          onLossMode={onLossMode}
          setOnLossMode={setOnLossMode}
        />
      </div>
      <div className="crashGame-history-container">
        <BetStats myBets={myBets} isLoading={isLoading} />
      </div>
    </div>
  );
};

export default memo(Limbo);
