import React, { useState, useContext } from "react";
import { Box, Modal } from "@mui/material";
import "./VIPClub.css";
import AccordionItem from "./AccordionItem/AccordionItem";
import TabContent from "../../Tabs/TabContent";
import { modalsControl } from "../../../Contexts/ModalsControl";
import Undermaintenance from "../../Undermaintenance";
import ShowComponent from "../../ShowComponent/ShowComponent";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 760,
  // height: "90vh",
  overflow: "auto",
  outline: "none",
};

export default function VIPClub() {
  const [selectedLevel, setSelectedLevel] = useState("bronze");
  const { VIPClubModal, setVipClub } = useContext(modalsControl);
  const handleClose = () => setVipClub(false);
  const handleSelectedLevel = (e) => {
    const { id } = e.target;
    setSelectedLevel(id);
  };
  const levels = [
    {
      level: "bronze",
      vipFrom: "1",
      vipTo: "20",
    },
    {
      level: "silver",
      vipFrom: "21",
      vipTo: "40",
    },
    {
      level: "gold",
      vipFrom: "41",
      vipTo: "60",
    },
    {
      level: "platinum",
      vipFrom: "61",
      vipTo: "80",
    },
  ];

  return (
    <Modal
      open={true}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Undermaintenance
          p1="Get ready to be showered with gifts."
          p2="Giveaways by joining our VIP CLUB. Stay tuned!"
          time=""
          type="soon"
          support={false}
        />
      </Box>
    </Modal>
  );
}
