import axiosClient from "..";

class MinesApis {
  static create_bet(data) {
    return axiosClient.post("/api/v1/mines/bet", data);
  }
  static my_bets() {
    return axiosClient.get("/api/v1/mines/bet");
  }
  static bet_update(data) {
    return axiosClient.post("/api/v1/mines/bet_update", data);
  }
  static bet_cashout(data) {
    return axiosClient.post("/api/v1/mines/bet_cashout", data);
  }
  static get_new_seed(data) {
    return axiosClient.post("/api/v1/mines/generate_server_seed", data);
  }
  static get_client_seed() {
    return axiosClient.get("/api/v1/mines/client_seed/new");
  }
  static getbox(data) {
    return axiosClient.get("/api/v1/mines/tile_index/new", data);
  }
  static showbox(data) {
    return axiosClient.get("/api/v1/mines/revealed_tile/show", data);
  }
  static rendompicktail() {
    return axiosClient.post("/api/v1/mines/random_bet_update");
  }
  static active_bet(data) {
    return axiosClient.get("/api/v1/mines/active_bet", data);
  }
}
export default MinesApis;
