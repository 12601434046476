import React from "react";
import { useTranslation } from "react-i18next";
import TransectionsPopup from "../../TransectionsPopup";

const DataWrapper = ({ transactions, loadingData, type }) => {
  const { t } = useTranslation();
  return loadingData ? (
    <div
      style={{
        top: "40%",
        left: "50%",
      }}
      className="lds-ellipsis relative"
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  ) : transactions.length ? (
    transactions.map((column) => {
      return (
        <div
          style={{
            padding: "10px 5px",
          }}
          className="transections-history-data my_row space-between my_row_item text_color"
        >
          <div className="time-data-amount-container width-55 my_row space-between">
            <span
              style={{
                fontSize: "10px",
              }}
              className="my_row_item_2 font-14-on-big-screen small-font-size-mobile"
            >
              {new Date(column.created_at).toLocaleDateString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </span>

            <span
              style={{
                fontSize: "10px",
              }}
              className="my_row_item_2 my_row white bold end font-14-on-big-screen small-font-size-mobile"
            >
              {column.amount}
              <img
                style={{
                  height: "1.4em",
                  width: "1.4em",
                  marginLeft: "0.2em",
                }}
                src={`${
                  column.token
                    ? "/imgs/AcceptedNetworks/usdt.webp"
                    : `/imgs/AcceptedNetworks/${column.network_id}.webp`
                }`}
                alt={column.network_id}
              />
            </span>
          </div>
          <div className="state-transection-contanier width-40 my_row space-between">
            <span
              style={{
                fontSize: "10px",
                color: "#3bc117",
              }}
              className="my_row_item_2 my_row font-14-on-big-screen small-font-size-mobile"
            >
              {column.status
                ? column.status === "failed"
                  ? t("header.wallet.transactionTab.unsuccessfull")
                  : column.status === "approved"
                  ? t("header.wallet.transactionTab.successfull")
                  : column.status === "rejected"
                  ? t("header.wallet.transactionTab.unsuccessfull")
                  : t("header.wallet.transactionTab.pending")
                : column.transaction_type === "Admin Deposit"
                ? t("header.wallet.transactionTab.adminDeposit")
                : t("header.wallet.transactionTab.deposit")}
            </span>

            <TransectionsPopup
              type={type}
              text={t("header.wallet.transactionTab.details")}
              message={
                column.transaction_type === "deposit"
                  ? t("header.wallet.transactionTab.deposit")
                  : t("header.wallet.withdraw")
              }
              id={column.id}
              topup={column.amount}
              hash={column.tx_hash}
              currency={column.network_id}
              time={new Date(column.created_at).toLocaleDateString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
              })}
            />
          </div>
        </div>
      );
    })
  ) : (
    <div
      style={{
        flexDirection: "column",
        marginTop: "60px",
      }}
      className="showEmpty-containern justified width"
    >
      <div className="show-empty-img-container">
        <img className="empty-max-width" src="/imgs/empty.png" alt="empty" />
      </div>

      <div className="show-empty-typo">
        <span style={{ fontSize: "14px" }} className="text_color">
          {t("header.referral.oop")}
        </span>
      </div>
    </div>
  );
};

export default DataWrapper;
