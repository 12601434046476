import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import userReducer from "./user/index";
import gameReducer from "./game/index";
import betReducer from "./bet/index";
import mineReducer from "./mine/index"

const persistConfig = {
  debug: false,
  throttle: 1000,
  version: 1,
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  user: userReducer,
  game: gameReducer,
  bets: betReducer,
  minesBet: mineReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
  // devTools: false,
});
const persistor = persistStore(store);
const unsubscribe = store.subscribe(() => {});
export { store, persistor };
