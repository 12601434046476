import React, { useState, useContext, useEffect } from "react";
// Redux imports
import { useSelector } from "react-redux";
// Context Imports
import { betPriceContext } from "../../../Contexts/BetPrice";
// APis import
import BetConfigration from "../../../../api/currency_bet_configrations";
// Addons import
import ShowComponent from "../../../Common/ShowComponent/ShowComponent";
import { useTranslation, Trans } from "react-i18next";
import Switch from "../../../Common/Header/Wallet/Currencies/Switch/Switch";

const PlayArea = ({
  betPlacer,
  alertMessage,
  configuredCurrency,
  betValues,
  setBetValues,
  inProgressBet,
  handleAutoBet,
  autoPlacedBets,
  setAutoPlacedBets,
  autoBetInProgress,
  calculatedAutoProfit,
  calculatedAutoLoss,
  onWinMode,
  setOnWinMod,
  setOnLossMode,
  onLossMode,
}) => {
  // Context states
  const { activatedCurrency } = useContext(betPriceContext);
  // Redux states
  const conversion_rates = useSelector((state) => state.game.conversion_rates);
  // Controlled states
  const [showSlider, setShowSlider] = useState(false);
  const [usdRate, setUsdRate] = useState("");
  const [calculatedUsdRate, setCalculatedUsdRate] = useState("");
  const [mode, setMode] = useState("manual");
  const [disabledFields, setDisabled] = useState(false);
  const { t } = useTranslation();
  // Form values setter
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setBetValues({ ...betValues, [name]: value });
  };
  // Helper functions
  const handleMode = (e) => {
    const { id } = e.target;
    setMode(id);
  };
  const handleToggleSlider = () => {
    setShowSlider(!showSlider);
  };
  const minBetSetter = () => {
    setBetValues({
      ...betValues,
      betAmount: parseFloat(configuredCurrency?.min_bet).toFixed(
        configuredCurrency?.precision
      ),
    });
  };
  const maxBetSetter = () => {
    setBetValues({
      ...betValues,
      betAmount: parseFloat(configuredCurrency?.max_bet).toFixed(
        configuredCurrency?.precision
      ),
    });
  };
  const handleMinBetAmount = () => {
    minBetSetter();
  };
  const handleMaxBetAmount = () => {
    maxBetSetter();
  };
  const multiplyBetAmount = () => {
    if (parseFloat(betValues?.betAmount) * 2 < configuredCurrency?.max_bet) {
      setBetValues({
        ...betValues,
        betAmount: (parseFloat(betValues?.betAmount) * 2).toFixed(
          configuredCurrency?.precision
        ),
      });
    } else {
      maxBetSetter();
    }
  };
  const divideBetAmount = () => {
    if (parseFloat(betValues?.betAmount) / 2 > configuredCurrency?.min_bet) {
      setBetValues({
        ...betValues,
        betAmount: (parseFloat(betValues?.betAmount) / 2).toFixed(
          configuredCurrency?.precision
        ),
      });
    } else {
      minBetSetter();
    }
  };
  const autoBetsOnChange = (e) => {
    const { value } = e.target;
    setBetValues({
      ...betValues,
      autoBets: parseInt(value),
    });
    if (autoPlacedBets) {
      setAutoPlacedBets(0);
    }
  };
  const autoBetsOnBlur = (e) => {
    const { value } = e.target;
    if (!value) {
      setBetValues({
        ...betValues,
        autoBets: 10,
      });
    }
    if (autoPlacedBets) {
      setAutoPlacedBets(0);
    }
  };
  const handleOnWinMode = () => {
    if (onWinMode === "reset") {
      setOnWinMod("increase");
    } else {
      setOnWinMod("reset");
    }
  };
  const handleOnLossMode = () => {
    if (onLossMode === "reset") {
      setOnLossMode("increase");
    } else {
      setOnLossMode("reset");
    }
  };
  // Input blur functions
  const handlePayoutBlur = (e) => {
    const { name, value } = e.target;
    if (!value) {
      setBetValues({ ...betValues, [name]: 1.01 });
    } else if (parseFloat(value) <= 0 || parseFloat(value) < 1.01) {
      setBetValues({ ...betValues, [name]: 1.01 });
    } else if (parseFloat(value) > 1000000.0) {
      setBetValues({ ...betValues, [name]: (1000000).toFixed(2) });
    } else {
      setBetValues({ ...betValues, [name]: parseFloat(value).toFixed(2) });
    }
  };
  const handleBetAmountBlur = (e) => {
    const { name, value } = e.target;
    if (!value || parseFloat(value) < 0) {
      minBetSetter();
    } else if (parseFloat(value) < configuredCurrency?.min_bet) {
      minBetSetter();
    } else if (parseFloat(value) > configuredCurrency?.max_bet) {
      maxBetSetter();
    } else {
      setBetValues({
        ...betValues,
        [name]: parseFloat(value).toFixed(configuredCurrency?.precision),
      });
    }
  };
  const stopLossProfitBlur = (e) => {
    const { name, value } = e.target;
    if (!value || parseFloat(value) < 0) {
      setBetValues({
        ...betValues,
        [name]: parseFloat(0).toFixed(configuredCurrency?.precision),
      });
    } else {
      setBetValues({
        ...betValues,
        [name]: parseFloat(value).toFixed(configuredCurrency?.precision),
      });
    }
  };
  const handleAutoBetsNumber = (e) => {
    const { name } = e.target;

    setBetValues({
      ...betValues,
      autoBets: parseInt(name),
    });
    setAutoPlacedBets(0);
  };
  const stopLossBlurHandle = (e) => {
    if (!e.target.value || parseFloat(e.target.value) < 0) {
      setBetValues({
        ...betValues,
        [e.target.name]: 0,
      });
    }
  };

  // Effects

  // USD rate finder
  useEffect(() => {
    setUsdRate(
      conversion_rates.conversion_rates.find(
        (rate) =>
          rate.from_currency === activatedCurrency.network_id &&
          rate.to_currency === "united_states_dollar"
      )
    );
  }, [activatedCurrency.network_id]);

  // USD rate calculater
  useEffect(() => {
    setCalculatedUsdRate(
      (parseFloat(usdRate?.rate) * parseFloat(betValues?.betAmount)).toFixed(4)
    );
  }, [betValues?.betAmount, usdRate?.rate]);

  useEffect(() => {
    if (!configuredCurrency?.min_bet) {
      return;
    }
    minBetSetter();
  }, [configuredCurrency?.min_bet, activatedCurrency.network_id]);

  useEffect(() => {
    if (autoBetInProgress || inProgressBet) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [autoBetInProgress, inProgressBet]);

  return (
    <div className="col-lg-3 mb-4 p-0 dark-bg-four order-lg-first">
      <div
        className={`p-3 game-play-area ${
          inProgressBet || autoBetInProgress ? "down-opacity" : ""
        }`}
      >
        <div className="d-flex text-center gap-1 fs-14">
          <button
            id="manual"
            className={`w-100 py-3 radius-3 orbitron-font ${
              mode === "manual" ? "dark-bg-two secondary_text" : "text_color"
            }`}
            onClick={handleMode}
            disabled={disabledFields}
          >
            {t("header.referral.manual")}
          </button>
          <button
            id="auto"
            className={`w-100 py-3 radius-3 orbitron-font ${
              mode === "auto" ? "dark-bg-two secondary_text" : "text_color"
            }`}
            onClick={handleMode}
            disabled={disabledFields}
          >
            {t("header.referral.auto")}
          </button>
        </div>
        <div className="mt-4 mb-4">
          <div className="">
            <div className="d-flex justify-content-between mb-2 fs-14 secondary_text">
              <p className="mb-0">
                {t("header.wallet.transactionTab.amount")} &nbsp;
              </p>
              <p className="mb-0">
                <span className="blue_color">
                  {activatedCurrency.network_id === "wb"
                    ? "0"
                    : !calculatedUsdRate || calculatedUsdRate === "NaN"
                    ? "..."
                    : calculatedUsdRate}
                </span>
                &nbsp;USD
              </p>
            </div>
            <div className="position-relative">
              <img
                src={`/imgs/AcceptedNetworks/${activatedCurrency?.network_id}.webp`}
                alt={activatedCurrency?.network_id}
                className="ms-3 position-absolute currency-icons top-50 translate-middle-y"
              />
              <div className="d-flex end-0 me-2 position-absolute top-50 translate-middle-y">
                <button
                  className="dark-bg-one ms-1 p-2 radius-3 secondary_text cursor-pointer"
                  onClick={divideBetAmount}
                >
                  <small className="fs-13">/</small>2
                </button>
                <button
                  className="dark-bg-one ms-1 p-2 radius-3 secondary_text cursor-pointer"
                  onClick={multiplyBetAmount}
                >
                  x2
                </button>
                <button
                  className="cursor-pointer dark-bg-one ms-1 p-2 radius-3 secondary_text range-slider-icon"
                  onClick={handleToggleSlider}
                >
                  <img
                    src="/assets/limbo/svg/price-slider.svg"
                    alt=""
                    style={{ width: "10px" }}
                  />
                </button>
              </div>
              <input
                type="number"
                name="betAmount"
                className={`${
                  autoBetInProgress || inProgressBet
                    ? "form-control-disabled"
                    : "form-control"
                } ps-5`}
                placeholder={t("stats.highrollers.betamount")}
                value={betValues.betAmount}
                onChange={handleOnChange}
                onBlur={handleBetAmountBlur}
                disabled={disabledFields}
              />
            </div>
          </div>

          <ShowComponent condition={showSlider}>
            <div className={`mt-3 d-flex gap-3 align-items-center`}>
              <button
                className="dark-bg-one fs-13 p-2 radius-3 secondary_text"
                onClick={handleMinBetAmount}
              >
                {t("header.wallet.withdrawTab.min")}
              </button>
              <input
                type="range"
                name="betAmount"
                className="form-range"
                id="customRange1"
                step="0.000001"
                value={betValues?.betAmount}
                onChange={handleOnChange}
                min={parseFloat(configuredCurrency?.min_bet)}
                max={parseFloat(configuredCurrency?.max_bet).toFixed(
                  configuredCurrency?.precision
                )}
                disabled={disabledFields}
              />
              <button
                className="dark-bg-one fs-13 p-2 radius-3 secondary_text"
                onClick={handleMaxBetAmount}
              >
                {t("header.wallet.swapTab.max")}
              </button>
            </div>
          </ShowComponent>
          <div className="mt-3">
            <div className="d-flex justify-content-between mb-2 fs-14 secondary_text">
              <p className="mb-0">{t("gamebox.betit.payout")}</p>
            </div>
            <input
              type="number"
              name="betPayout"
              className={`${
                autoBetInProgress || inProgressBet
                  ? "form-control-disabled"
                  : "form-control"
              }`}
              placeholder="0.00000751"
              value={betValues.betPayout}
              onChange={handleOnChange}
              onBlur={handlePayoutBlur}
              disabled={disabledFields}
            />
          </div>
        </div>
        <ShowComponent condition={mode === "auto"}>
          <div
            style={{
              marginBottom: "1.5rem",
            }}
            className=""
          >
            <div className="d-flex justify-content-between mb-2 fs-14 secondary_text">
              <p className="mb-0">{t("gamebox.betit.betnumber")}: &nbsp;</p>
            </div>
            <div className="position-relative">
              <div className="d-flex end-0 me-2 position-absolute top-50 translate-middle-y">
                <button
                  name="0"
                  className="dark-bg-one ms-1 p-2 radius-3 secondary_text cursor-pointer"
                  onClick={handleAutoBetsNumber}
                >
                  &infin;
                </button>
                <button
                  name="10"
                  className="dark-bg-one ms-1 p-2 radius-3 secondary_text cursor-pointer"
                  onClick={handleAutoBetsNumber}
                >
                  10
                </button>
                <button
                  name="100"
                  className="cursor-pointer dark-bg-one ms-1 p-2 radius-3 secondary_text range-slider-icon"
                  onClick={handleAutoBetsNumber}
                >
                  100
                </button>
              </div>
              <input
                type="number"
                name="betAmount"
                className={`${
                  autoBetInProgress || inProgressBet
                    ? "form-control-disabled"
                    : "form-control"
                }`}
                placeholder={t("gamebox.betit.autobets")}
                value={
                  betValues.autoBets == 0
                    ? 0
                    : betValues.autoBets - autoPlacedBets
                }
                onChange={autoBetsOnChange}
                onBlur={autoBetsOnBlur}
                disabled={disabledFields}
              />
            </div>
          </div>
        </ShowComponent>

        <ShowComponent condition={mode === "auto"}>
          <div className="mt-4 mb-4">
            <div className="">
              <div className="d-flex justify-content-between mb-2 fs-14 secondary_text">
                <p className="mb-0">{t("gamebox.betit.onwin")}</p>
              </div>
              <div className="position-relative justified">
                <button
                  className="width-50 justified"
                  onClick={handleOnWinMode}
                >
                  <div
                    style={{
                      transform: "rotate(90deg)",
                    }}
                    className="switch-capsule"
                  >
                    <Switch
                      activeStatus={onWinMode === "increase" ? true : false}
                      onClickHandle={handleOnWinMode}
                    />
                  </div>
                  <div className="typo-graphy-container my_row column">
                    <div className="reset-text">
                      <span className="secondary_text">
                        {t("gamebox.betit.reset")}
                      </span>
                    </div>
                    <div className="increase-text">
                      <span
                        className={`${
                          onWinMode === "increase" ? "secondary_text" : "white"
                        }`}
                      >
                        {t("gamebox.betit.increaseby")}
                      </span>
                    </div>
                  </div>
                </button>

                <input
                  type="number"
                  name="increaseOnWin"
                  style={{
                    width: "50%",
                  }}
                  className={`${
                    autoBetInProgress || inProgressBet
                      ? "form-control-disabled"
                      : "form-control"
                  }`}
                  placeholder={t("gamebox.betit.increasewin")}
                  value={betValues.increaseOnWin}
                  onChange={handleOnChange}
                  onBlur={stopLossBlurHandle}
                  disabled={disabledFields}
                />
              </div>
            </div>
          </div>
        </ShowComponent>
        <ShowComponent condition={mode === "auto"}>
          <div className="mt-4 mb-4">
            <div className="">
              <div className="d-flex justify-content-between mb-2 fs-14 secondary_text">
                <p className="mb-0">{t("gamebox.betit.stopprofit")}: </p>
                <p>
                  {t("gamebox.betit.currentprofit")}:{" "}
                  {parseFloat(calculatedAutoProfit).toFixed(
                    configuredCurrency?.precision
                  )}
                </p>
              </div>
              <div className="position-relative">
                <img
                  src={`/imgs/AcceptedNetworks/${activatedCurrency?.network_id}.webp`}
                  alt={activatedCurrency?.network_id}
                  className="ms-3 position-absolute currency-icons top-50 translate-middle-y"
                />
                <input
                  type="number"
                  name="stopProfit"
                  className={`${
                    autoBetInProgress || inProgressBet
                      ? "form-control-disabled"
                      : "form-control"
                  } ps-5`}
                  placeholder={t("gamebox.betit.stopprofit")}
                  value={betValues.stopProfit}
                  onChange={handleOnChange}
                  onBlur={stopLossProfitBlur}
                  disabled={disabledFields}
                />
              </div>
            </div>
          </div>
        </ShowComponent>
        <ShowComponent condition={mode === "auto"}>
          <div className="mt-4 mb-4">
            <div className="">
              <div className="d-flex justify-content-between mb-2 fs-14 secondary_text">
                <p className="mb-0">{t("gamebox.betit.onloss")}</p>
              </div>
              <div className="position-relative justified">
                <button
                  className="width-50 justified"
                  onClick={handleOnLossMode}
                >
                  <div
                    style={{
                      transform: "rotate(90deg)",
                    }}
                    className="switch-capsule"
                  >
                    <Switch
                      activeStatus={onLossMode === "increase" ? true : false}
                      onClickHandle={handleOnLossMode}
                    />
                  </div>
                  <div className="typo-graphy-container my_row column">
                    <div className="reset-text">
                      <span className="secondary_text">
                        {t("gamebox.betit.reset")}
                      </span>
                    </div>
                    <div className="increase-text">
                      <span
                        className={`${
                          onLossMode === "increase" ? "secondary_text" : "white"
                        }`}
                      >
                        {t("gamebox.betit.increaseby")}
                      </span>
                    </div>
                  </div>
                </button>
                <input
                  type="number"
                  name="decreaseOnLoss"
                  style={{
                    width: "50%",
                  }}
                  className={`${
                    autoBetInProgress || inProgressBet
                      ? "form-control-disabled"
                      : "form-control"
                  }`}
                  placeholder={t("gamebox.betit.increaseloss")}
                  value={betValues.decreaseOnLoss}
                  onChange={handleOnChange}
                  onBlur={stopLossBlurHandle}
                  disabled={disabledFields}
                />
              </div>
            </div>
          </div>
        </ShowComponent>

        <ShowComponent condition={mode === "auto"}>
          <div className="mt-4 mb-4">
            <div className="">
              <div className="d-flex justify-content-between mb-2 fs-14 secondary_text">
                <p className="mb-0">{t("gamebox.betit.stoploss")}</p>
                <p className="mb-0">
                  {t("gamebox.betit.currentloss")}:{" "}
                  {parseFloat(calculatedAutoLoss).toFixed(
                    configuredCurrency?.precision
                  )}
                </p>
              </div>
              <div className="position-relative">
                <img
                  src={`/imgs/AcceptedNetworks/${activatedCurrency?.network_id}.webp`}
                  alt={activatedCurrency?.network_id}
                  className="ms-3 position-absolute currency-icons top-50 translate-middle-y"
                />
                <input
                  type="number"
                  name="stopLoss"
                  className={`${
                    autoBetInProgress || inProgressBet
                      ? "form-control-disabled"
                      : "form-control"
                  } ps-5`}
                  placeholder={t("gamebox.betit.stoploss")}
                  value={betValues.stopLoss}
                  onChange={handleOnChange}
                  onBlur={stopLossProfitBlur}
                  disabled={disabledFields}
                />
              </div>
            </div>
          </div>
        </ShowComponent>

        <ShowComponent condition={mode === "auto"}>
          <div className="">
            {autoBetInProgress ? (
              <button
                className="orbitron-font primary-btn primary-btn-bg bet-button-bubbles w-100"
                onClick={handleAutoBet}
              >
                {t("gamebox.betit.stopbet")}
              </button>
            ) : (
              <button
                className="orbitron-font primary-btn primary-btn-bg bet-button-bubbles w-100"
                onClick={handleAutoBet}
              >
                {t("gamebox.betit.startbet")}
              </button>
            )}
          </div>
        </ShowComponent>

        <ShowComponent condition={mode === "manual"}>
          <div className="">
            {inProgressBet ? (
              <button className="orbitron-font primary-btn primary-btn-disable bet-button-bubbles w-100">
                {t("header.referral.inprocess")}
              </button>
            ) : (
              <button
                className="orbitron-font primary-btn primary-btn-bg bet-button-bubbles w-100"
                onClick={betPlacer}
              >
                {t("gamebox.betit.placebet")}
              </button>
            )}
          </div>
        </ShowComponent>

        <ShowComponent condition={alertMessage.length}>
          <div className="alert alert-danger m-0 mt-4 mb-4 text-center">
            {alertMessage}
          </div>
        </ShowComponent>
      </div>
    </div>
  );
};
export default PlayArea;
