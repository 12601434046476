import "./Mines.css";
import React, { useState, useContext, useEffect } from "react";
import useSound from "use-sound";
import CashoutSound from "./Addons/Sound/win.mp3";
import WinSound from "./Addons/Sound/gems.mp3";
import loseSound from "./Addons/Sound/mines.mp3";
import playSound from "./Addons/Sound/bet.mp3";
import PlayGround from "./Elements/PlayGround";
import MineArea from "./Elements/MineArea";
import BetStats from "./Elements/Stats/Stats";
// Contexts import
import { betPriceContext } from "../../Contexts/BetPrice";
// Redux state setter imports
import { setUserWallets } from "../../../store/user";
// API imports
import UserWalletsAPI from "../../../api/user_wallets";
import MinesAPis from "../../../api/Mines/mines";
import PoolAPI from "../../../api/pool";
import { modalsControl } from "../../Contexts/ModalsControl";
import { setMinesBetData } from "../../../store/mine";
import MinesApis from "../../../api/Mines/mines";
import { useDispatch, useSelector } from "react-redux";
import BetConfigration from "../../../api/currency_bet_configrations";
import LoadingScreens from "../../Common/LoadingScreens/LoadingScreens";
import Undermaintenance from "../../Common/Undermaintenance";

const Mines = () => {
  const [MinesSound] = useSound(loseSound);
  const [GemsSound] = useSound(WinSound);
  const [CreateSound] = useSound(playSound);
  const [WinCashoutSound] = useSound(CashoutSound);
  // Redux states
  const dispatch = useDispatch();
  const user_id = useSelector((state) => state.user.currentUser?.user_id);
  // Context states
  const { activatedCurrency } = useContext(betPriceContext);
  // Helper Variables
  const inputInitials = {
    betAmount: 1,
    mines: 1,
    network_id: activatedCurrency.network_id,
  };

  const { setSignInOpen, showTrends, setShowTrends } =
    useContext(modalsControl);
  const [alertMessage, setAlertMessage] = useState("");
  const [placed, setPlaced] = useState(false);
  const [inProgressBet, setInprogressBet] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [myBets, setMyBets] = useState([]);
  const [pool_amounts, setPoolAmounts] = useState([]);
  const [betValues, setBetValues] = useState(inputInitials);
  const [selectedPool, setSelectedPool] = useState([]);
  const [fetchRuningBet, setFetchRuningBet] = useState(true);
  const [currencyConfigrations, setCurrencyConfigrations] = useState([]);
  const [cashOutPopup, setCashOutPopup] = useState(false);
  const [activeBetMines, setActiveBetMines] = useState(1);
  const [activeBetWallet, setActoiveBetWallet] = useState("");

  // Bet Associated States
  const [configuredCurrency, setConfiguredCurrency] = useState({});
  // Helper functions
  const findConfiguredCurrency = () => {
    setConfiguredCurrency(
      currencyConfigrations?.find(
        (currency) => currency.network_id === activatedCurrency.network_id
      )
    );
  };
  const [grid, setGrid] = useState([]);
  useEffect(() => {
    BetConfigration.bet_configrations("mines")
      .then((res) => {
        setCurrencyConfigrations(res.data.currency_configuration);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    findConfiguredCurrency();
  }, [currencyConfigrations.length, activatedCurrency.network_id]);

  const HideBoxes = () => {
    MinesAPis.getbox()
      .then((res) => {
        setGrid(res.data.data.tile_inedx_array);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    HideBoxes();
  }, []);

  const showboxes = () => {
    MinesAPis.showbox()
      .then((res) => {
        setGrid(res.data.data.revealed_tiles_arry);
        setInprogressBet(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleCheckBlock = (e) => {
    e.preventDefault();
    const { id } = e.target;
    MinesAPis.bet_update({
      update_param: {
        tile_index: parseInt(id),
      },
    })
      .then((res) => {
        if (res.data.data.remaining_gems === 0) {
          betPayout();
        } else {
          let triggeredBlock = grid.find(
            (block) => block.id === parseFloat(id)
          );
          triggeredBlock.revealed = true;
          triggeredBlock.status = "revealed";
          triggeredBlock.type = res.data.data.type;
          dispatch(
            setMinesBetData({
              next_payout: res.data.data.next_payout,
              next_multiplier: res.data.data.next_multiplier,
              current_multiplier: res.data.data.current_multiplier,
              current_payout: res.data.data.current_payout,
              remaining_gems: res.data.data.remaining_gems,
            })
          );
          if (res.data.data.type === "mine") {
            showboxes();
            fetchBetsHistory();
            setPlaced(false);
            MinesSound();
          } else {
            GemsSound();
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const RandomlyTile = () => {
    MinesApis.rendompicktail()
      .then((res) => {
        if (res.data.data.remaining_gems === 0) {
          betPayout();
        } else {
          let triggeredBlock = grid.find(
            (block) => block.id === res.data.data.index
          );
          triggeredBlock.revealed = true;
          triggeredBlock.status = "revealed";
          triggeredBlock.type = res.data.data.type;
          dispatch(
            setMinesBetData({
              next_payout: res.data.data.next_payout,
              next_multiplier: res.data.data.next_multiplier,
              current_multiplier: res.data.data.current_multiplier,
              current_payout: res.data.data.current_payout,
              remaining_gems: res.data.data.remaining_gems,
            })
          );
          if (res.data.data.type === "mine") {
            setPlaced(false);
            showboxes();
            MinesSound();
            fetchUserWallets();
            fetchBetsHistory();
          } else {
            GemsSound();
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchPoolAmounts = () => {
    PoolAPI.getPoolAmounts("mines").then((response) => {
      setPoolAmounts(response?.data?.pool_values);
    });
  };
  const fetchActiveBets = () => {
    if (!fetchRuningBet) {
      setFetchRuningBet(true);
    }
    MinesAPis.active_bet()
      .then((res) => {
        setInprogressBet(true);
        setFetchRuningBet(false);
        setPlaced(true);
        setGrid(res.data.data.tiles_arry);
        setActiveBetMines(res.data.data.mines);
        setBetValues({ ...betValues, betAmount: res.data.data.amount });
        setActoiveBetWallet(res.data.data.network_id);
        dispatch(
          setMinesBetData({
            next_payout: res.data.data.next_payout,
            next_multiplier: res.data.data.next_multiplier,
            current_multiplier: res.data.data.current_multiplier,
            current_payout: res.data.data.current_payout,
            remaining_gems: res.data.data.remaining_gems,
          })
        );
      })
      .catch((err) => {
        setInprogressBet(false);
        setFetchRuningBet(false);
      });
  };

  const fetchBetsHistory = () => {
    if (user_id) {
      setIsLoading(true);
      MinesAPis.my_bets()
        .then((response) => {
          setMyBets(response.data.data.user_bets);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    if (!user_id) {
      setFetchRuningBet(false);
      setMyBets([]);
      if (placed) {
        setPlaced(false);
      }
      return;
    }
    fetchBetsHistory();
    fetchActiveBets();
  }, [user_id]);
  const fetchUserWallets = () => {
    UserWalletsAPI.getUserWallets().then((response) => {
      dispatch(
        setUserWallets({
          wallets: response.data,
        })
      );
    });
  };
  const handleBetPlace = () => {
    if (!user_id) {
      setSignInOpen(true);
      return;
    }
    if (inProgressBet) {
      return;
    }
    MinesAPis.create_bet({
      mine_param: {
        amount: betValues.betAmount,
        mine: betValues.mines,
        network_id: activatedCurrency.network_id,
      },
    })
      .then((res) => {
        CreateSound();
        HideBoxes();
        setCashOutPopup(false);
        fetchUserWallets();
        setPlaced(true);
        // fetchActiveBets();
        // setInprogressBet(true);
        setInprogressBet(true);
        setActoiveBetWallet(activatedCurrency.network_id);

        fetchBetsHistory();
        // fetchPoolAmounts();
        dispatch(
          setMinesBetData({
            next_payout: res.data.data.next_payout,
            next_multiplier: res.data.data.next_multiplier,
            current_multiplier: res.data.data.current_multiplier,
            current_payout: res.data.data.current_payout,
            remaining_gems: res.data.data.remaining_gems,
          })
        );
      })
      .catch((err) => {
        setInprogressBet(false);
        setAlertMessage(err.response.data.message);
        setTimeout(() => {
          setAlertMessage("");
        }, 3000);
      });
  };
  // Fetch my history & pool amounts
  useEffect(() => {
    fetchPoolAmounts();
  }, []);
  // Select the desired pool
  useEffect(() => {
    setSelectedPool(
      pool_amounts?.find(
        (element) => element.network_id === activatedCurrency?.network_id
      )
    );
  }, [activatedCurrency?.network_id, pool_amounts]);

  useEffect(() => {
    setBetValues({ ...betValues, mines: activeBetMines });
  }, [fetchRuningBet]);

  const betPayout = () => {
    MinesAPis.bet_cashout()
      .then((res) => {
        fetchUserWallets();
        showboxes();
        setPlaced(false);
        setCashOutPopup(true);
        setInprogressBet(false);
        fetchBetsHistory();
        WinCashoutSound();
        dispatch(
          setMinesBetData({
            next_payout: 0,
            next_multiplier: 0,
            current_multiplier: res.data.data.multiplier,
            current_payout: res.data.data.payout,
            remaining_gems: 25 - betValues.mines,
          })
        );
      })
      .catch((err) => {
        setInprogressBet(false);
        setAlertMessage(err.response.data.message);
        setTimeout(() => {
          setAlertMessage("");
        }, 3000);
      });
  };

  useEffect(() => {
    dispatch(
      setMinesBetData({
        remaining_gems: 25 - betValues.mines,
      })
    );
  }, [betValues.mines]);

  useEffect(() => {
    if (showTrends) {
      setShowTrends(false);
    }
  }, []);
  useEffect(() => {
    document.title =
      "Mines Game: Discover the world of Crypto Mines Game - Start mining today!";
  }, []);
  if (fetchRuningBet) {
    return <LoadingScreens screen="mines" />;
  } else

  return (
    <div className="main-wrapper-container">
      <div className="row m-0">
        <PlayGround
          activatedCurrency={activatedCurrency}
          myBets={myBets}
          selectedPool={selectedPool}
          grid={grid}
          setGrid={setGrid}
          cashOutPopup={cashOutPopup}
          betValues={betValues}
          handleCheckBlock={handleCheckBlock}
          fetchBetsHistory={fetchBetsHistory}
        />
        <MineArea
          alertMessage={alertMessage}
          configuredCurrency={configuredCurrency}
          betPlacer={handleBetPlace}
          RandomlyTile={RandomlyTile}
          betValues={betValues}
          setBetValues={setBetValues}
          placed={placed}
          cashout={betPayout}
          inProgressBet={inProgressBet}
          activeBetWallet={activeBetWallet}
        />
      </div>

      <div className="crashGame-history-container">
        <BetStats myBets={myBets} isLoading={isLoading} />
      </div>
    </div>
  );
};

export default Mines;
