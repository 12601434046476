import { useContext } from "react";
import { modalsControl } from "../Contexts/ModalsControl";
import Countdown from "react-countdown";
import ShowComponent from "./ShowComponent/ShowComponent";

const Undermaintenance = ({ p1, p2, time, support, type }) => {
  const { liveSupport, setLiveSupport } = useContext(modalsControl);
  const handleLiveSupport = () => {
    setLiveSupport(!liveSupport);
  };
  return (
    <div className="undermaintenance">
      <div className="undermaintenance-area">
        <img className="logo" alt="logo" src="/imgs/logo/logo.png" />
        <p className="common f">
          {p1}
          <ShowComponent condition={time}>
            <span className="t">
              <Countdown date={time} />.
            </span>
          </ShowComponent>
        </p>
        <p className="common l">{p2}</p>

        <ShowComponent condition={support}>
          <button
            className="primary-btn primary-btn-bg mt-4"
            onClick={handleLiveSupport}
          >
            Live Support
          </button>
        </ShowComponent>

        <div className="icon-wrap">
          <a href="https://twitter.com/bcwclub" target="_blank">
            <img src="/imgs/underconstruction/twittwr.svg" alt="twitter" />
          </a>
          <a href="https://www.instagram.com/bcwclubgames/" target="_blank">
            <img src="/imgs/underconstruction/instagram.svg" alt="instagram" />
          </a>
        </div>
        <img
          className="update"
          alt="update"
          src={`/imgs/underconstruction/${type}-img.png`}
        />
      </div>
    </div>
  );
};
export default Undermaintenance;
