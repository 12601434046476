import axiosClient from "..";

class PlinkoApis {
  static create_bet(data) {
    return axiosClient.post("/api/v1/plinko/bets", data);
  }
  static fetch_bonusline(rows, risk) {
    return axiosClient.get(
      `/api/v1/plinko/bonus_lines?rows=${rows}&risk=${risk}`
    );
  }

  static fetch_bets() {
    return axiosClient.get(`/api/v1/plinko/bets`);
  }
  static client_seed(data) {
    return axiosClient.get("/api/v1/plinko/client_seed/new",data);
  }
  static generate_server_seed(data) {
    return axiosClient.post("/api/v1/plinko/generate_server_seed",data);
  }
}
export default PlinkoApis;
