import React from 'react'
import "./LoadingScreens.css";

const LoadingScreens = ({screen}) => {
  return (
    <div className={`common-loading-screens ${screen}-loading-screen`}>
        <div className={`common-loading-screens-inner ${screen}-loading-screen-inner`}>
            <img src={`/assets/loading-screens/${screen}-loading-screen.webp`} className="width-auto" alt="" />
        </div>
    </div>
  )
}

export default LoadingScreens
