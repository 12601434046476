import React from "react";
import ReactDOM from "react-dom/client";
import { BetPriceProvider } from "./components/Contexts/BetPrice";
import App from "./App";
import "./index.css";
import Web3 from "web3";
import { Provider, useSelector } from "react-redux";

import { Web3ReactProvider } from "@web3-react/core";
import { MetaMaskProvider } from "./hooks/metamask";
import { PersistGate } from "redux-persist/integration/react";

import { persistor, store } from "./store";
import { MultiplierProvider } from "./components/Contexts/Multiplier";
import { DistributorProvider } from "./components/Contexts/multiplierDistributor";
import { ModalsControlContextProvider } from "./components/Contexts/ModalsControl";
import { BetsControlProvider } from "./components/Contexts/Bets";
import { TrendsProvider } from "./components/Contexts/TrendsDistributor";
import { SocketBetsConnection } from "./components/Contexts/SocketBets";
import "./i18n";
import { UserBetProvider } from "./components/Contexts/UserBetHandler";
import { LoadingContextProvider } from "./components/Contexts/Loading";
import { ConfiguredCurrencyProvider } from "./components/Contexts/ConfiguredCurrency/ConfiguredCurrency";

// LogRocket.identify(uid);

const root = ReactDOM.createRoot(document.getElementById("root"));
function getLibrary(provider, connector) {
  return new Web3(provider);
}

root.render(
  <BetPriceProvider>
    <ModalsControlContextProvider>
      <SocketBetsConnection>
        <MultiplierProvider>
          <DistributorProvider>
            <Web3ReactProvider getLibrary={getLibrary}>
              <MetaMaskProvider>
                <Provider store={store}>
                  <PersistGate loading={null} persistor={persistor}>
                    <BetsControlProvider>
                      <TrendsProvider>
                        <UserBetProvider>
                          <LoadingContextProvider>
                            <ConfiguredCurrencyProvider>
                              <App />
                            </ConfiguredCurrencyProvider>
                          </LoadingContextProvider>
                        </UserBetProvider>
                      </TrendsProvider>
                    </BetsControlProvider>
                  </PersistGate>
                </Provider>
              </MetaMaskProvider>
            </Web3ReactProvider>
          </DistributorProvider>
        </MultiplierProvider>
      </SocketBetsConnection>
    </ModalsControlContextProvider>
  </BetPriceProvider>
);
