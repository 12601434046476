import React, { useState } from "react";
// Addon components
import MultiplierButton from "../Addons/MultiplierButton";
// Additional Imports
import { useTranslation } from "react-i18next";
import LimboPopup from "./LimboPopup";
import ShowComponent from "../../../Common/ShowComponent/ShowComponent";
const Graph = ({
  rocketState,
  multiplierNumber,
  selectedPool,
  activatedCurrency,
  myBets,
  inProgressBet,
  blast,
  fetchBetsHistory,
  maxProfitApplied,
}) => {
  const { t } = useTranslation();

  // Controlled states
  const [betId, setBetId] = useState(null);
  const [limboModal, setLimboModal] = useState(false);

  return (
    <div className="col-lg-9 mb-4 p-0 dark-bg-four">
      <div class="limbo-game p-3 pb-0">
        <div class="align-content-center d-flex justify-content-start">
          <div class="bankroll d-flex dark-bg-two flex-column justify-content-center me-2 p-1 px-4 radius-4 text-center">
            <p class="d-flex gap-2 justify-content-center mb-1 orbitron-font text-success">
              {t("header.referral.bankroll")}{" "}
              <span class="text-color-secondary">
                {activatedCurrency?.symbol}
              </span>
            </p>
            <p class="mb-0 text-white d-flex justify-content-center gap-2">
              {selectedPool?.amount}
              <img
                src={`/imgs/AcceptedNetworks/${activatedCurrency?.network_id}.webp`}
                alt="BetCoin"
                class="currency-icons top-0"
              />
            </p>
          </div>
          <ul class="align-items-center d-flex flex-fill justify-content-end games-trends-bar gap-2 orbitron-font fs-14">
            <MultiplierButton
              data={myBets}
              setBetId={setBetId}
              setLimboModal={setLimboModal}
            />
          </ul>
        </div>
        <div className="multiplierRocket">
          <div class="limbo-multiplier source-code-pro-font">
            {parseFloat(multiplierNumber).toFixed(2)}x
            <div class={`limbo-rocket-blast ${blast && "blast"}`}></div>
          </div>
          <ShowComponent condition={maxProfitApplied}>
            <div className="mines-cashout-popup-containert d-flex ">
              <div className="mines-cashout-popup ">
                <p>Max Profit Applied</p>
              </div>
            </div>
          </ShowComponent>
          <div
            class={`limbo-rocket text-center limbo-rocket-img ${
              rocketState && "limbo-rocketBounceOutUp"
            }`}
          >
            <div class={inProgressBet ? "static-rocket" : "limbo-rocketShake"}>
              <img
                src="/assets/limbo/images/rocket.webp"
                className="w-auto"
                alt=""
              />
              <div class="limbo-cloud-dust"></div>
            </div>
          </div>
        </div>
      </div>
      <ShowComponent condition={limboModal}>
        <LimboPopup
          game="limbo"
          limboModal={limboModal}
          setLimboModal={setLimboModal}
          betId={betId}
          myBets={myBets}
          fetchBetsHistory={fetchBetsHistory}
        />
      </ShowComponent>
    </div>
  );
};
export default Graph;
