import React, { useState, useContext, useEffect } from "react";
// Redux imports
import { useSelector } from "react-redux";
// Context imports
import { betPriceContext } from "../../../Contexts/BetPrice";
// Addons import
import ShowComponent from "../../../Common/ShowComponent/ShowComponent";

import { useTranslation } from "react-i18next";

const Playarea = ({
  betValues,
  setBetValues,
  createBet,
  inProgressBet,
  configuredCurrency,
  alertMessage,
  droppingBalls,
  handleAutoButtonClick,
  autoBetActive,
  setAutoBetActive,
  autoBetInProgress,
}) => {
  const { t } = useTranslation();
  // Helper Variables
  const rows = [8, 9, 10, 11, 12, 13, 14, 15, 16];
  const risk = [
    { intensity: t("header.sidebar.low"), risk: 0 },
    { intensity: t("header.sidebar.medium"), risk: 1 },
    { intensity: t("header.sidebar.high"), risk: 2 },
  ];
  // Context states
  const { activatedCurrency } = useContext(betPriceContext);
  // Redux states
  const conversion_rates = useSelector((state) => state.game.conversion_rates);
  // Controlled states
  const [showSlider, setShowSlider] = useState(false);
  const [usdRate, setUsdRate] = useState("");
  const [calculatedUsdRate, setCalculatedUsdRate] = useState("");
  const [mode, setMode] = useState("manual");

  // Form values setter
  const handleOnChange = (e) => {
    if (droppingBalls) {
      return;
    }
    const { name, value } = e.target;
    setBetValues({ ...betValues, [name]: value });
  };

  const autoBetsOnChange = (e) => {
    const { value } = e.target;
    setBetValues({
      ...betValues,
      autoBets: parseInt(value),
    });
    if (autoBetActive) {
      setAutoBetActive(0);
    }
  };
  const autoBetsOnBlur = (e) => {
    const { value } = e.target;
    if (!value) {
      setBetValues({
        ...betValues,
        autoBets: 10,
      });
    }
    if (autoBetActive) {
      setAutoBetActive(0);
    }
  };

  // Helper functions
  const handleMode = (e) => {
    if (inProgressBet) {
      return;
    }
    const { id } = e.target;
    setMode(id);
  };
  const handleToggleSlider = () => {
    if (inProgressBet) {
      return;
    }
    setShowSlider(!showSlider);
  };
  const minBetSetter = () => {
    setBetValues({
      ...betValues,
      betAmount: parseFloat(configuredCurrency?.min_bet).toFixed(
        configuredCurrency?.precision
      ),
    });
  };
  const maxBetSetter = () => {
    setBetValues({
      ...betValues,
      betAmount: parseFloat(configuredCurrency?.max_bet).toFixed(
        configuredCurrency?.precision
      ),
    });
  };
  const handleMinBetAmount = () => {
    minBetSetter();
  };
  const handleMaxBetAmount = () => {
    maxBetSetter();
  };
  const multiplyBetAmount = () => {
    if (inProgressBet) {
      return;
    }
    if (parseFloat(betValues?.betAmount) * 2 < configuredCurrency?.max_bet) {
      setBetValues({
        ...betValues,
        betAmount: (parseFloat(betValues?.betAmount) * 2).toFixed(
          configuredCurrency?.precision
        ),
      });
    } else {
      maxBetSetter();
    }
  };
  const divideBetAmount = () => {
    if (inProgressBet) {
      return;
    }
    if (parseFloat(betValues?.betAmount) / 2 > configuredCurrency?.min_bet) {
      setBetValues({
        ...betValues,
        betAmount: (parseFloat(betValues?.betAmount) / 2).toFixed(
          configuredCurrency?.precision
        ),
      });
    } else {
      minBetSetter();
    }
  };
  // Input blur functions
  const handleBetAmountBlur = (e) => {
    const { name, value } = e.target;
    if (!value || parseFloat(value) < 0) {
      minBetSetter();
    } else if (parseFloat(value) < configuredCurrency?.min_bet) {
      minBetSetter();
    } else if (parseFloat(value) > configuredCurrency?.max_bet) {
      maxBetSetter();
    } else {
      setBetValues({
        ...betValues,
        [name]: parseFloat(value).toFixed(configuredCurrency?.precision),
      });
    }
  };

  const handleAutoBetsNumber = (e) => {
    const { name } = e.target;

    setBetValues({
      ...betValues,
      autoBets: parseInt(name),
    });
    setAutoBetActive(0);
  };

  // USD rate finder
  useEffect(() => {
    setUsdRate(
      conversion_rates.conversion_rates.find(
        (rate) =>
          rate.from_currency === activatedCurrency.network_id &&
          rate.to_currency === "united_states_dollar"
      )
    );
  }, [activatedCurrency.network_id]);

  // USD rate calculater
  useEffect(() => {
    setCalculatedUsdRate(
      (parseFloat(usdRate?.rate) * parseFloat(betValues?.betAmount)).toFixed(4)
    );
  }, [betValues?.betAmount, usdRate?.rate]);

  useEffect(() => {
    if (!configuredCurrency?.min_bet) {
      return;
    }
    minBetSetter();
  }, [configuredCurrency?.min_bet, activatedCurrency.network_id]);
  return (
    <div className="col-lg-3 mb-4 p-0 dark-bg-four order-lg-first">
      <div className="p-3 game-play-area">
        <div className="d-flex text-center gap-1 fs-14">
          <button
            id="manual"
            className={`w-100 py-3 radius-3 orbitron-font ${
              mode === "manual" ? "dark-bg-two secondary_text" : "text_color"
            }`}
            onClick={handleMode}
          >
            {t("header.referral.manual")}
          </button>
          <button
            id="auto"
            className={`w-100 py-3 radius-3 orbitron-font ${
              mode === "auto" ? "dark-bg-two secondary_text" : "text_color"
            }`}
            onClick={handleMode}
          >
            {t("header.referral.auto")}
          </button>
        </div>

        <ShowComponent condition={mode === "manual"}>
          <div className="mt-4 mb-4">
            <div className="">
              <div className="d-flex justify-content-between mb-2 fs-14 text-color-secondary">
                <p className="mb-0">
                  {t("header.wallet.transactionTab.amount")}&nbsp;
                </p>
                <p className="mb-0">
                  <span className="blue_color">
                    {activatedCurrency.network_id === "wb"
                      ? "0"
                      : !calculatedUsdRate || calculatedUsdRate === "NaN"
                      ? "..."
                      : calculatedUsdRate}
                  </span>
                  &nbsp;USD
                </p>
              </div>
              <div className="position-relative">
                <img
                  src={`/imgs/AcceptedNetworks/${activatedCurrency?.network_id}.webp`}
                  alt={activatedCurrency?.network_id}
                  className="ms-3 position-absolute currency-icons top-50 translate-middle-y"
                />
                <div className="d-flex end-0 me-2 position-absolute top-50 translate-middle-y">
                  <button
                    className="dark-bg-one ms-1 p-2 radius-3 text-color-secondary cursor-pointer"
                    onClick={divideBetAmount}
                  >
                    <small className="fs-13">/</small>2
                  </button>
                  <button
                    className="dark-bg-one ms-1 p-2 radius-3 text-color-secondary cursor-pointer"
                    onClick={multiplyBetAmount}
                  >
                    x2
                  </button>
                  <button
                    className="cursor-pointer dark-bg-one ms-1 p-2 radius-3 text-color-secondary range-slider-icon"
                    onClick={handleToggleSlider}
                  >
                    <img
                      src="/assets/plinko/svg/price-slider.svg"
                      alt=""
                      style={{ width: "10px" }}
                    />
                  </button>
                </div>
                <input
                  type="number"
                  name="betAmount"
                  className="form-control ps-5"
                  placeholder={t("stats.highrollers.betamount")}
                  value={betValues.betAmount}
                  onChange={handleOnChange}
                  onBlur={handleBetAmountBlur}
                  disabled={droppingBalls}
                />
              </div>
            </div>
            <ShowComponent condition={showSlider}>
              <div className={`mt-4 d-flex gap-3 align-items-center`}>
                <button
                  className="dark-bg-one fs-13 p-2 radius-3 text-color-secondary"
                  onClick={handleMinBetAmount}
                >
                  {t("header.wallet.withdrawTab.min")}
                </button>
                <input
                  type="range"
                  name="betAmount"
                  className="form-range"
                  id="customRange1"
                  step="0.000001"
                  value={betValues?.betAmount}
                  onChange={handleOnChange}
                  min={parseFloat(configuredCurrency?.min_bet)}
                  max={parseFloat(configuredCurrency?.max_bet).toFixed(
                    configuredCurrency?.precision
                  )}
                />
                <button
                  className="dark-bg-one fs-13 p-2 radius-3 text-color-secondary"
                  onClick={handleMaxBetAmount}
                >
                  {t("header.wallet.swapTab.max")}
                </button>
              </div>
            </ShowComponent>

            <div className="mt-4">
              <div className="d-flex justify-content-between mb-2 fs-14 text-color-secondary">
                <p className="mb-0">{t("header.sidebar.Rows")}</p>
              </div>
              <select
                name="row"
                id="row"
                className="form-control uppercase"
                value={betValues.row}
                onChange={handleOnChange}
                disabled={droppingBalls}
              >
                {rows.map((row) => (
                  <option value={row}>{row}</option>
                ))}
              </select>
            </div>
            <div className="mt-4">
              <div className="d-flex justify-content-between mb-2 fs-14 text-color-secondary">
                <p className="mb-0">{t("header.sidebar.Risk")}</p>
              </div>
              <select
                name="risk"
                id="risk"
                className="form-control uppercase"
                value={betValues.risk}
                onChange={handleOnChange}
                disabled={droppingBalls}
              >
                {risk.map((risk) => (
                  <option value={risk.risk}>{risk.intensity}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="">
            <button
              className="orbitron-font primary-btn primary-btn-bg bet-button-bubbles w-100"
              onClick={createBet}
              disabled={inProgressBet}
            >
              {t("gamebox.betit.placebet")}
            </button>
          </div>

          <ShowComponent condition={alertMessage?.length}>
            <div className="alert alert-danger m-0 mt-4 mb-4 text-center">
              {alertMessage}
            </div>
          </ShowComponent>
        </ShowComponent>
        <ShowComponent condition={mode === "auto"}>
          <div className="mt-4 mb-4">
            <div className="">
              <div className="d-flex justify-content-between mb-2 fs-14 text-color-secondary">
                <p className="mb-0">
                  {t("header.wallet.transactionTab.amount")} &nbsp;
                </p>

                <p className="mb-0">
                  <span className="blue_color">
                    {activatedCurrency.network_id === "wb"
                      ? "0"
                      : !calculatedUsdRate || calculatedUsdRate === "NaN"
                      ? "..."
                      : calculatedUsdRate}
                  </span>
                  &nbsp;USD
                </p>
              </div>
              <div className="position-relative">
                <img
                  src={`/imgs/AcceptedNetworks/${activatedCurrency?.network_id}.webp`}
                  alt={activatedCurrency?.network_id}
                  className="ms-3 position-absolute currency-icons top-50 translate-middle-y"
                />
                <div className="d-flex end-0 me-2 position-absolute top-50 translate-middle-y">
                  <button
                    className="dark-bg-one ms-1 p-2 radius-3 text-color-secondary cursor-pointer"
                    onClick={divideBetAmount}
                  >
                    <small className="fs-13">/</small>2
                  </button>
                  <button
                    className="dark-bg-one ms-1 p-2 radius-3 text-color-secondary cursor-pointer"
                    onClick={multiplyBetAmount}
                  >
                    x2
                  </button>
                  <button
                    className="cursor-pointer dark-bg-one ms-1 p-2 radius-3 text-color-secondary range-slider-icon"
                    onClick={handleToggleSlider}
                  >
                    <img
                      src="/assets/plinko/svg/price-slider.svg"
                      alt=""
                      style={{ width: "10px" }}
                    />
                  </button>
                </div>
                <input
                  type="number"
                  name="betAmount"
                  className="form-control ps-5"
                  placeholder={t("stats.highrollers.betamount")}
                  value={betValues.betAmount}
                  onChange={handleOnChange}
                  onBlur={handleBetAmountBlur}
                  disabled={droppingBalls}
                />
              </div>
            </div>
            <ShowComponent condition={showSlider}>
              <div className={`mt-4 d-flex gap-3 align-items-center`}>
                <button
                  className="dark-bg-one fs-13 p-2 radius-3 text-color-secondary"
                  onClick={handleMinBetAmount}
                >
                  {t("header.wallet.withdrawTab.min")}
                </button>
                <input
                  type="range"
                  name="betAmount"
                  className="form-range"
                  id="customRange1"
                  step="0.000001"
                  value={betValues?.betAmount}
                  onChange={handleOnChange}
                  min={parseFloat(configuredCurrency?.min_bet)}
                  max={parseFloat(configuredCurrency?.max_bet).toFixed(
                    configuredCurrency?.precision
                  )}
                />
                <button
                  className="dark-bg-one fs-13 p-2 radius-3 text-color-secondary"
                  onClick={handleMaxBetAmount}
                >
                  {t("header.wallet.swapTab.max")}
                </button>
              </div>
            </ShowComponent>
            <div className="mt-4 ">
              <div className="d-flex justify-content-between mb-2 fs-14 text-color-secondary">
 
                <p className="mb-0">{t("gamebox.betit.betnumber")}</p>
 
              </div>
              <div className="position-relative">
                <div className="d-flex end-0 me-2 position-absolute top-50 translate-middle-y">
                  <button
                    className="dark-bg-one ms-1 p-2 radius-3 text-color-secondary cursor-pointer"
                    onClick={handleAutoBetsNumber}
                    name="0"
                  >
                    &infin;
                  </button>
                  <button
                    className="dark-bg-one ms-1 p-2 radius-3 text-color-secondary cursor-pointer"
                    onClick={handleAutoBetsNumber}
                    name="10"
                  >
                    10
                  </button>
                  <button
                    className="dark-bg-one ms-1 p-2 radius-3 text-color-secondary cursor-pointer"
                    onClick={handleAutoBetsNumber}
                    name="100"
                  >
                    100
                  </button>
                </div>
                <input
                  type="number"
                  name="autoBets"
                  className="form-control"
                  placeholder="0"
                  value={
                    betValues.autoBets == 0
                      ? autoBetActive - betValues.autoBets
                      : betValues.autoBets - autoBetActive
                  }
                  onChange={autoBetsOnChange}
                  onBlur={autoBetsOnBlur}
                />
              </div>
            </div>

            <div className="mt-4">
              <div className="d-flex justify-content-between mb-2 fs-14 text-color-secondary">
                <p className="mb-0">{t("header.sidebar.Rows")}</p>
              </div>
              <select
                name="row"
                id="row"
                className="form-control uppercase"
                value={betValues.row}
                onChange={handleOnChange}
                disabled={droppingBalls}
              >
                {rows.map((row) => (
                  <option value={row}>{row}</option>
                ))}
              </select>
            </div>
            <div className="mt-4">
              <div className="d-flex justify-content-between mb-2 fs-14 text-color-secondary">
                <p className="mb-0">{t("header.sidebar.Risk")}</p>
              </div>
              <select
                name="risk"
                id="risk"
                className="form-control uppercase"
                value={betValues.risk}
                onChange={handleOnChange}
                disabled={droppingBalls}
              >
                {risk.map((risk) => (
                  <option value={risk.risk}>{risk.intensity}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="">
            {autoBetInProgress ? (
              <button
                className="orbitron-font primary-btn primary-btn-bg bet-button-bubbles w-100"
                onClick={handleAutoButtonClick}
              >
 
                {t("gamebox.betit.stopbet")}
 
              </button>
            ) : (
              <button
                className="orbitron-font primary-btn primary-btn-bg bet-button-bubbles w-100"
                onClick={handleAutoButtonClick}
              >
 
                {t("gamebox.betit.startbet")}
 
              </button>
            )}
          </div>
        </ShowComponent>
      </div>
    </div>
  );
};

export default Playarea;
