import React, { useState, memo } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import LanguageIcon from "@mui/icons-material/Language";
import { useTranslation, Trans } from "react-i18next";
function Languages({ setLanguageSelector }) {
  const { t, i18n } = useTranslation();
  // AvailableLanguages
  const lngs = {
    English: { nativeName: "English" },
    Indonesia: { nativeName: "Indonesia" },
    ไทย: { nativeName: "ไทย" },
  };

  const setLang = (lng) => {
    i18n.changeLanguage(lng);
    setLanguageSelector();
  };
  return (
    <div className="languages-main-container">
      <div className="languages-inner-container">
        <div className="languages-content-container">
          {Object.keys(lngs).map((lng) => (
            <>
              <button
                className="side-bar-language width centered_text white relative"
                key={lng}
                type="submit"
                onClick={() => setLang(lng)}
              >
                <span>{lngs[lng].nativeName}</span>
                <div
                  className={`
                  language-active-indicator ${
                    i18n.resolvedLanguage === lng
                      ? "active-language-indicator"
                      : "inactive-language-indicator"
                  } absolute

                  `}
                ></div>
              </button>
            </>
          ))}
        </div>
      </div>
    </div>
  );
}

export default memo(Languages);
