import consumer from "./cable";
const UserChannel = consumer.subscriptions.create("UserChannel", {
  connected() {
    console.log("user channel connected");
  },
  disconnected() {
    console.log("user channel disconnected");
  },
  received(data) {},
});
export default UserChannel;
