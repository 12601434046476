import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    next_payout: 0,
    next_multiplier: 0,
    current_multiplier: 0,
    current_payout: 0,
    remaining_gems: 0,
};
const minesBetSlice = createSlice({
    name: "minesBet",
    initialState,
    reducers: {
        setMinesBetData: (state, action) => {
            state.next_payout = action.payload.next_payout;
            state.next_multiplier = action.payload.next_multiplier;
            state.current_multiplier = action.payload.current_multiplier;
            state.current_payout = action.payload.current_payout;
            state.remaining_gems= action.payload.remaining_gems
        },
    },
});



export const { setMinesBetData } = minesBetSlice.actions;
export default minesBetSlice.reducer;