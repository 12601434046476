import { useState, useEffect, memo, useContext } from "react";
import { useSelector } from "react-redux";
import Counter from "./Counter";
import Multiplier from "./Multiplier";
import { constants } from "../../../../../store/constants";
import Curve from "./rocket/Curve.svg";
import CoinsStack from "./rocket/CoinsStack.webp";
import { betPriceContext } from "../../../../Contexts/BetPrice";
import { useTranslation } from "react-i18next";
import { multiplierContext } from "../../../../Contexts/Multiplier";
import { betsControl } from "../../../../Contexts/Bets";
import { multiplierDistributor } from "../../../../Contexts/multiplierDistributor";

const Chart = ({ showProfit, setShowProfit }) => {
  const { myChannel, unSubFromSocket } = useContext(multiplierContext);
  const [underMaintainance, setUndermaintainance] = useState(false);
  const server_state_time = useSelector((state) => {
    return state.game.server_state_time;
  });
  const [acitveX1, setacitveX1] = useState(true);
  const [acitveX2, setacitveX2] = useState(true);
  const [acitveX3, setacitveX3] = useState(true);
  const [activeY1, setactivey1] = useState(true);
  const [activeY2, setactivey2] = useState(true);
  const [userDropping, setUserdropping] = useState(false);
  const { bets, tempIndices } = useContext(betsControl);
  const { globaleGameState } = useContext(betPriceContext);
  const game_state = useSelector((state) => state.game.game_state);
  const cashed_out_multiplier = useSelector(
    (state) => state.user.cashedOutMultiplier
  );
  const user_id = useSelector((state) => state.user.currentUser?.user_id);
  // const myBet = bets[user_id];
  const [secondScaleShrink, setSecondScaleShrink] = useState(0);
  const server_game_state_loading = useSelector(
    (state) => state.game.server_game_state_loading
  );
  const [dropIt, setDropIt] = useState(false);
  const [startLine, setStartLine] = useState(false);
  const [userPosition, setPosition] = useState(false);
  const xAxis1 = [2, 4, 6, 8, 10, 12, 14, 16, 18, 20];
  const xAxis2 = [10, 20, 30, 40];
  const xAxis3 = [10, 20, 40, 60, 80, 100];
  const yAxis1 = [1.8, 1.6, 1.4, 1.2];
  const yAxis2 = [19, 17, 15, 13, 11, 7, 5, 3, 2];
  const startGraph = () => {
    setStartLine(true);
    setTimeout(() => {
      setPosition(true);
    }, 1000);

    setTimeout(() => {
      setDropIt(true);
      setUserdropping(true);
    }, 6000);

    setTimeout(() => {
      setactivey1(false);
      setactivey2(true);
    }, 10000);
    setTimeout(() => {
      setacitveX2(true);
    }, 20000);
    setTimeout(() => {
      setacitveX2(false);
      setacitveX3(true);
    }, 40000);
  };
  useEffect(() => {
    if (game_state === constants.COUNTDOWN_STARTED) {
      setStartLine(false);

      setTimeout(() => {
        // settransition(10);
      }, 5);
    }
  }, [game_state]);
  useEffect(() => {
    if (game_state === constants.MULTIPLIER_STARTED) {
      setTimeout(() => {
        // settransition(10);
      }, 5);

      startGraph();
    }
  }, [game_state]);
  const [seconds, setSeconds] = useState(0);
  const [secondScaleMove, setSecondScaleMove] = useState(0);
  const [scaleCount, setScaleCount] = useState(0);
  const [modulus, setModulus] = useState(seconds % 10);
  const [secondScale, setSecondScale] = useState(1);
  const [countDown, setCountDown] = useState(0);
  const { multiplier, setMultiplier, interval, setIntervalTime } = useContext(
    multiplierDistributor
  );
  let stopper = false;
  const [width, setWidth] = useState(100);
  const [height, setHeight] = useState(0);
  const [droppingMove, setDroppingMove] = useState(-50);
  const [transition, settransition] = useState(0);
  const [scale, setScale] = useState([]);
  const scaleInitiator = [
    6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 15, 17, 18, 19, 20,
  ];
  const [multiplierScale, setMultiplierScale] = useState([1.8, 1.6, 1.4, 1.2]);
  const [oldInterval, setOldInterval] = useState(0);
  let myTimeOut = setTimeout(() => {
    return;
  }, 110);

  useEffect(() => {
    if (game_state === constants.MULTIPLIER_STARTED && interval <= 90) {
      if (parseFloat(oldInterval) < 100) {
        myTimeOut = setTimeout(() => {
          setMultiplier((parseFloat(multiplier) + 0.01).toFixed(2));
          setOldInterval(oldInterval + parseFloat(interval));
        }, interval);
      }
    }
  }, [oldInterval]);

  useEffect(() => {
    if (game_state === constants.MULTIPLIER_STOPPED) {
      clearTimeout(myTimeOut);
    }
  }, [game_state]);

  useEffect(() => {
    if (scaleCount === 0) {
      setScale(["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"]);
    } else {
      setSecondScaleShrink(22);

      setScale(
        scaleInitiator.map((initVal) => {
          return initVal + scaleCount * 10 - 10;
        })
      );
    }
  }, [scaleCount]);
  useEffect(() => {
    if (game_state === constants.MULTIPLIER_STARTED) {
      if (scaleCount === 0) {
        settransition(1);
        setWidth(100 - seconds * 10);
        setHeight(0 + seconds * 10 - 10);
      } else {
        settransition(0);
        setWidth(0);
        setHeight(90);
        setDroppingMove(50);
      }
    }
  }, [seconds, game_state]);
  useEffect(() => {
    if (scaleCount !== 0) {
      if (secondScaleMove + 5.625 < 1125) {
        setSecondScaleMove(secondScaleMove + 5.625);
      } else {
        setSecondScaleMove(1125);
      }
    }
  }, [multiplier]);
  useEffect(() => {
    if (modulus === 0) {
      setSecondScaleMove(0);
    }
  }, [modulus]);
  useEffect(() => {
    setModulus(seconds % 10);
    setScaleCount(parseInt(seconds / 10));
    if (game_state === constants.MULTIPLIER_STARTED && scaleCount === 0) {
      if (droppingMove + 10 <= 50) {
        setDroppingMove(droppingMove + 10);
      }
    }
  }, [seconds]);
  useEffect(() => {
    if ((seconds / 10) % 1 === 0) {
      setScaleCount(scaleCount + 1);
    }
  }, [seconds]);
  const run = async () => {
    for await (let data of myChannel) {
      setSeconds(data.time);
      if (data.status === "countdown") {
        setCountDown(data.value);
      }
      if (data.status === "checkout") {
        setOldInterval(0);
        clearTimeout(myTimeOut);
        setMultiplier(data.value);
        stopper = true;
        // if (data.interval !== interval) {
        // clearInterval(myTimeOut);
        // }
        setIntervalTime(data.interval);

        if (data.time >= 10) {
          const newArr = multiplierScale.reverse();
          if (data.value !== newArr[0]) {
            setMultiplierScale((prevArray) => [...prevArray, data.value]);
          }
        }
      }
      if (data.status === "checkout_stop") {
        // window.multiplier = data.value;
        setMultiplier(data.value);

        if (data.time >= 10) {
          const newArr = multiplierScale.reverse();
          if (data.value !== newArr[0]) {
            setMultiplierScale((prevArray) => [...prevArray, data.value]);
          }
        }
      }
    }
  };
  useEffect(() => {
    if (multiplierScale.length > 10) {
      const reference = multiplierScale.reverse();
      setMultiplierScale(
        reference.filter((val, index) => index >= 0 && index <= 9).reverse()
      );
    }
  }, [multiplierScale]);
  useEffect(() => {
    if (game_state === constants.MULTIPLIER_STOPPED) {
      setIntervalTime(100);
      setOldInterval(0);
      // setRoundCount((roundCount) => roundCount + 1);
      setMultiplierScale([1.8, 1.6, 1.4, 1.2]);
    }
  }, [game_state]);
  useEffect(() => {
    settransition(0);
    if (game_state === constants.MULTIPLIER_STARTED) {
      setMultiplier(1.01);
    }
    if (game_state === constants.COUNTDOWN_STARTED) {
      setMultiplier(1.01);
      setMultiplierScale([]);
      setHeight(0);
      setDroppingMove(-50);
    }
  }, [game_state]);
  useEffect(() => {
    settransition(0);
    setModulus(0);
    setScaleCount(0);
    setSecondScaleShrink(0);
    setWidth(100);
  }, [game_state]);
  useEffect(() => {
    run();
  }, [myChannel]);
  const { t } = useTranslation();

  return (
    <div
      style={{
        overflow: "hidden",
      }}
      className="betchart-class my_row justify relative white"
    >
      <div
        style={{
          position: "absolute",
          // left: "18%",
          zIndex: "10",
          opacity: showProfit ? "100%" : "0%",
          transition: "1s",
        }}
        className="UserWinAmmount-container absolute"
      >
        <div
          style={{
            scale: showProfit ? "65%" : "0%",
            transition: "1s",
          }}
        >
          <div
            style={{
              textAlign: "center",
            }}
            className="userWinAmount-profit"
          >
            <span
              style={{
                fontSize: "20px",
                color: "#f6c722",
                marginRight: "5px",
              }}
            >
              {t("gamebox.graph.youWon")}
            </span>
            <span
              style={{
                fontSize: "20px",
                fontWeight: "600px",
              }}
            >
              {parseFloat(cashed_out_multiplier).toFixed(8)}
              {/* {typeof cashed_out_multiplier + typeof bet_amount} */}
            </span>
          </div>
          <div className="userWinAmount-coinsStack">
            <img src={CoinsStack} alt="CoinsStack" />
          </div>
        </div>
      </div>

      <h1
        className="graph-connecting-size orbitron-font"
        style={{
          position: "absolute",
          top: "35%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: "19",
        }}
      >
        {underMaintainance ? (
          t("header.wallet.withdrawTab.undermatainance")
        ) : globaleGameState && game_state !== constants.MULTIPLIER_STOPPED ? (
          t("gamebox.graph.connecting")
        ) : game_state === constants.COUNTDOWN_STARTED ? (
          <Counter
            countDown={countDown}
            seconds={seconds}
            scaleCount={scaleCount}
            modulus={modulus}
          />
        ) : game_state === constants.MULTIPLIER_STARTED ? (
          <div className="runMultiplier my_row">
            <Multiplier multiplier={multiplier} />
          </div>
        ) : (
          game_state === constants.MULTIPLIER_STOPPED && (
            <Multiplier multiplier={multiplier} />
          )
        )}

        {/* {underMaintainance
          ? "Under maintainance"
          : } */}
      </h1>

      <div
        style={{
          width: "600px",
          height: "280px",
          borderLeft: "1px solid #3f3f3f",
          borderBottom: "1px solid #3f3f3f",
        }}
        className="graph relative"
      >
        <div
          style={{
            width: "4px",
            height: "280px",
            left: "-40px",
            bottom: "0px",
            width: "30px",
            fontSize: "8px",
            color: "grey",
          }}
          className="multiplier-yAxis absolute"
        >
          <div
            style={{
              bottom: "0px",
              right: "0px",
              display: "flex",
              flexDirection: "column",
            }}
            className="multiplier-distributor absolute"
          >
            {/* {activeY2 &&
              yAxis2.map((multiplier, index) => (
                <div
                  key={index}
                  style={{ paddingBottom: Paddingy2 ? "19px" : "235px" }}
                  className="multiplier-container-y2"
                >
                  {multiplier}x
                </div>
              ))} */}
            {
              // activeY1 &&
              multiplierScale.map((multiplier, index) => (
                <div key={index} className="multiplier-container-y1">
                  {multiplier}x
                </div>
              ))
            }
          </div>
        </div>
        <div
          style={{
            background: "#17181b",
            right: "0",
            bottom: "0",
            width: `${width}%`,
            height: "100%",
            transition: `${transition}s linear`,
            zIndex: "1",
          }}
          className="toMakeCurve absolute"
          id="toMakeCurve"
        ></div>
        <div
          style={{
            height: "100%",
            bottom: "0px",
            width: "100%",
            /* right: 0px; */
            left: "0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "end",
          }}
          className="animated-curve absolute"
        >
          <img src={Curve} alt="" />
        </div>
        <div
          style={{
            // background: "orange",
            // opacity: "40%",
            bottom: "0",
            left: "0px",
            width: "100%",
            height: `${height}%`,
            zIndex: "1",
            transition: `all ${
              game_state === constants.COUNTDOWN_STARTED
                ? "0s lilnear"
                : seconds < 6
                ? transition + 2
                : transition + 2
            }s linear`,
          }}
          className="greendiv absolute"
        >
          <div
            style={{
              height: "98%",
              // background: "black",
              // opacity: "40%",
              transition: "2s linear",
              left: `${droppingMove}%`,
              width: "100%",
              bottom: "0",
            }}
            className="absolute userDroppingPoint relative"
          >
            {/* {/* {bets.map((betItem, index) => { */}

            {tempIndices.map((betIndex) => {
              let betItem = bets[betIndex];
              return (
                <div
                  key={betIndex}
                  style={{
                    top: betItem?.cash_out > 1 ? "90%" : "0px",
                    // transition: dropIt ? "0s" : "5s",
                    transition: "5s",
                    opacity: betItem?.cash_out > 1 ? "0%" : "100%",
                    // opacity: "100%",
                    position: "absolute",
                    height: "20px",
                    zIndex: 1,
                  }}
                  className="dropping-user-container width"
                >
                  <div
                    style={{
                      left: betItem?.cash_out > 1 ? "0px" : "50%",

                      transform: "translatex(-39px)",

                      transition: "10s",
                    }}
                    className="for-the-left absolute"
                  >
                    <div className="dropper-tip-container width justified">
                      <div
                        style={{
                          background: "rgba(153, 164, 176, 0.6)",
                          width: "5px",
                          height: "5px",
                          borderRadius: "50%",
                          opacity: betItem?.cash_out > 1 ? "100%" : "0%",
                          // opacity: "100%",
                          transition: betItem?.cash_out > 1 ? "3s" : "hidden",
                        }}
                        className="dropping-userPoing"
                      ></div>
                    </div>
                    <div className="user-cashout-container width justified">
                      <span
                        className="text_color"
                        // className="white"
                        style={{
                          fontSize: "9px",
                          color: "white",
                          opacity: betItem?.cash_out > 1 ? "100%" : "0%",
                          // opacity: "100%",
                          transition: betItem?.cash_out > 1 ? "3s" : "0s",
                        }}
                      >
                        {betItem?.username + " @ " + betItem?.cash_out}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div
          style={{
            width: "600px",
            height: "30px",
            bottom: "-40px",
            color: "grey",
            fontSize: "8px",
          }}
          className="timer-x-axis absolute"
        >
          <div
            style={{
              left: "5px",
            }}
            className="zero-starter absolute"
          >
            0
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "nowrap",
              width: scaleCount !== 0 ? "80%" : "91%",
              right: "0px",
              overflow: "hidden",
              height: "100%",
            }}
            className="seconds-distributor absolute"
          >
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                left: `-${secondScaleMove}px`,
                // transition: `${secondMoveAnimation}s`,
              }}
              className="seconds-distributor-inner space-between width absolute"
            >
              {scale.map((second, index) => (
                <div
                  key={index}
                  style={{
                    paddingRight: `${secondScaleShrink}%`,
                  }}
                  className="second-container-scale"
                >
                  {second}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(Chart);
