import React, { useState, useEffect, useContext, createContext } from "react";
import { betPriceContext } from "../BetPrice";
import BetConfigration from "../../../api/currency_bet_configrations";

const configuredCurrencyContext = createContext();

const ConfiguredCurrencyProvider = ({ children }) => {
  const { activatedCurrency } = useContext(betPriceContext);
  const [currencyConfigrations, setCurrencyConfigrations] = useState([]);
  const [configuredCurrency, setConfiguredCurrency] = useState({});

  useEffect(() => {
    BetConfigration.bet_configrations("limbo")
      .then((res) => {
        setCurrencyConfigrations(res.data.currency_configuration);
      })
      .catch((err) => console.log(err));
  }, []);

  const findConfiguredCurrency = () => {
    setConfiguredCurrency(
      currencyConfigrations?.find(
        (currency) => currency.network_id === activatedCurrency.network_id
      )
    );
  };

  useEffect(() => {
    findConfiguredCurrency();
  }, [currencyConfigrations.length, activatedCurrency.network_id]);

  return (
    <configuredCurrencyContext.Provider value={{ configuredCurrency }}>
      {children}
    </configuredCurrencyContext.Provider>
  );
};
export { configuredCurrencyContext, ConfiguredCurrencyProvider };
