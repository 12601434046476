import React from "react";

const KenoBox = ({ value, variant, onClick }) => {
  const kenoBoxVariantss = {
    static: "keno_static",
    selected: "keno_clicked",
    serverSelected: "keno-lose",
    serverMatched: "keno-diamond",
  };
  return (
    <button className={kenoBoxVariantss[variant]} id={value} onClick={onClick}>
      <span className="keno-digit">{value}</span>
    </button>
  );
};

export default KenoBox;
