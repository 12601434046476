import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { modalsControl } from "../../Contexts/ModalsControl";

import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import SignInAPI from "../../../api/signin";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch, useSelector } from "react-redux";
import { setVerified } from "../../../store/user";

export default function VerifyEmail() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    height: 200,
    background: "linear-gradient(to right, #6f33ee 50%, #6124d4 50%)",
    boxShadow: 24,
    borderRadius: "20px",
    outline: "none",
    p: 4,
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { verifyMailModal, setVerifyMailModal } =
    React.useContext(modalsControl);

  const password_reset_token = searchParams.get("confirmation_token");

  const [verified, setVerifiedLocal] = React.useState(false);
  const [rejected, setRejected] = React.useState(false);
  const [verifying, setVerifying] = React.useState(false);

  useEffect(() => {
    if (verifyMailModal) {
      setVerifying(true);
      SignInAPI.emailVerify(password_reset_token)
        .then((res) => {
          setTimeout(() => {
            navigate("/");
            setVerifyMailModal(false);
          }, 3000);
          setVerifying(false);
          setVerifiedLocal(true);

          dispatch(
            setVerified({
              verified: true,
            })
          );
        })
        .catch((err) => {
          setTimeout(() => {
            navigate("/");
            setVerifyMailModal(false);
          }, 3000);
          setVerifying(false);
        });
    }
  }, [verifyMailModal]);

  const handleClose = () => setVerifyMailModal(false);

  return (
    <>
      <Modal
        open={verifyMailModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="modalsOn-mobile signin-signup modals-height-on-mobiles"
          sx={style}
        >
          <div className="modal-inner-main">
            <div className="modal-inner-inner">
              <div className="modal-inner-content">
                <div className="modal-inner-img-container relative">
                  <img
                    onClick={handleClose}
                    src="/imgs/logo/logo.png"
                    alt="BCW.CLUB"
                  />
                </div>
              </div>
              <div className="verification-status-container">
                <div
                  style={{ padding: "5px 0", height: "80px" }}
                  className="verification-content my_row space-between"
                >
                  <span className="gc bold">
                    {verifying
                      ? "Verifying your Email."
                      : verified
                      ? "Email Vefiried Successfully."
                      : "Email not Verified please verify again"}
                  </span>

                  <div className="verification-icon-container">
                    {verifying ? (
                      <div className="lds-ripple">
                        <div></div>
                        <div></div>
                      </div>
                    ) : verified ? (
                      <CheckCircleIcon />
                    ) : (
                      <CancelIcon />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
