import React from "react";
import { useTranslation, Trans } from "react-i18next";
const MultiplierButton = ({ data, setBetId, setMineModal }) => {
  const { t } = useTranslation();
  const assignBetId = (e) => {
    const { id } = e.target;
    setBetId(id);
    setMineModal(true);
  };
  if (!data.length) {
    return <li className="dark-bg-one">{t("header.sidebar.BETHISTORY")}</li>;
  } else
    return data.map(
      (history, index) =>
        index < 7 && (
          <li
            id={history?.id}
            key={index}
            className={`bold ${
              history.win_status ? "bg-success" : "bg-danger"
            }`}
            onClick={assignBetId}
          >
            {parseFloat(history?.multiplier).toFixed(2)}x
          </li>
        )
    );
};

export default MultiplierButton;
