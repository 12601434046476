import React, { useContext, useState } from "react";
import TabNavItem from "../Tabs/TabNavItem";
import TabContent from "../Tabs/TabContent";
import Currencies from "../Header/Wallet/Currencies/Currencies/Currencies";
import { useSelector } from "react-redux";
import { modalsControl } from "../../Contexts/ModalsControl";

const AssetsPortfolio = ({ children, activeCurrency, first, second }) => {
  const wallets = useSelector((state) => state.user.wallets);
  const hide_Small = useSelector((state) => state.game.hide_Small);
  const [filterId, setFilterId] = useState("");
  const { activeTab, setActiveTab } = useContext(modalsControl);

  return (
    <div className="currency-selection-search-dialog">
      <div className="fiat-crypto-currencies px-3">
        <div className="tabs-scroll">
          <div className="tabs-navs justified mb-1 currency-tabs-navs">
            <TabNavItem
              title="Crypto"
              id="crypto"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              disabled={first}
            />
            <TabNavItem
              title="Fiat"
              id="fiat"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              disabled={second}
            />
          </div>
        </div>
        <div className="tabs-view">
          <TabContent id="crypto" activeTab={activeTab}>
            <Currencies
              walletType="crypto"
              activeWallets={wallets}
              activeCurrency={activeCurrency}
              hide_Small={hide_Small}
              filterId={filterId}
              activeTab={activeTab}
              setFilterId={setFilterId}
            />
          </TabContent>
          <TabContent id="fiat" activeTab={activeTab}>
            <Currencies
              walletType="fiat"
              activeWallets={wallets}
              activeCurrency={activeCurrency}
              hide_Small={hide_Small}
              filterId={filterId}
              activeTab={activeTab}
              setFilterId={setFilterId}
            />
          </TabContent>
        </div>
      </div>
      {children}
    </div>
  );
};

export default AssetsPortfolio;
