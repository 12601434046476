import React, { useState, useEffect } from "react";
// Redux imports
import { useDispatch, useSelector } from "react-redux";
import pinSound from "../../Sound/pin.mp3";
import useSound from "use-sound";
import { setUserWalletAmount } from "../../../../../store/user";

const BallMap = ({
  directions,
  rows,
  setBinLocation,
  setMyBets,
  setInprogressBet,
  fetchPoolAmounts,
  droppingBalls,
  setDroppingBalls,
}) => {
  const [BallPinSound] = useSound(pinSound);

  const [horizontalPosition, setHorizontalPosition] = useState(0);
  const [verticalPosition, setVerticalPosition] = useState(0);
  const [index, setIndex] = useState(0);
  const [iterate, setIterate] = useState(true);
  const dispatch = useDispatch();
  const indexIncrement = () => {
    setTimeout(() => {
      setIndex((index) => index + 1);
      if (!droppingBalls) {
        setDroppingBalls(true);
      }
    }, 400);
  };
  const moveRight = () => {
    BallPinSound();
    setHorizontalPosition((horizontalPosition) => horizontalPosition + 19);
    setTimeout(() => {
      setVerticalPosition((verticalPosition) => verticalPosition + 38);
    }, 100);
    setTimeout(() => {
      setVerticalPosition((verticalPosition) => verticalPosition - 19);
    }, 300);
    setTimeout(() => {
      setVerticalPosition((verticalPosition) => verticalPosition + 19);
    }, 400);

    if (directions.directions_boolean[index + 1]) {
      indexIncrement();
    } else {
      return;
    }
  };
  const moveLeft = () => {
    BallPinSound();
    setHorizontalPosition((horizontalPosition) => horizontalPosition - 19);
    setTimeout(() => {
      setVerticalPosition((verticalPosition) => verticalPosition + 38);
    }, 100);
    setTimeout(() => {
      setVerticalPosition((verticalPosition) => verticalPosition - 19);
    }, 300);
    setTimeout(() => {
      setVerticalPosition((verticalPosition) => verticalPosition + 19);
    }, 400);
    if (directions.directions_boolean[index + 1]) {
      indexIncrement();
    } else {
      return;
    }
  };
  useEffect(() => {
    if (!directions.directions_boolean.length) {
      return;
    }
    directions.directions_boolean[index] === 1
      ? moveRight()
      : directions.directions_boolean[index] === -1 && moveLeft();
  }, [index, directions.directions_boolean.length]);

  useEffect(() => {
    if (!iterate) {
      return;
    }
    if (verticalPosition === 38 * rows && iterate) {
      setIterate(false);
      setBinLocation(directions.bin_location);
      setMyBets((myBets) => [directions.bet_history, ...myBets]);
      setInprogressBet(false);
      fetchPoolAmounts();
      dispatch(
        setUserWalletAmount({
          network_id: directions.bet_history.network_id,
          amount: directions.user_wallet_final_amount,
        })
      );
      if (droppingBalls) {
        setDroppingBalls(false);
      }
      // console.log("directions ::: ", directions);
      // alert("Here is the vertical position ::: ", 38 * rows);
    }
  }, [verticalPosition]);

  return (
    <div
      style={{
        transform: `translate(${horizontalPosition}px, ${verticalPosition}px)`,
        transition: ".2s linear",
      }}
      className="plinko-ball-container plinko-bet-ball"
    >
      <img
        src="/assets/plinko/svg/ball.svg"
        className={`width-auto plinko-ball ball-roll-${directions.directions_boolean[index]}`}
        alt=""
      />
    </div>
  );
};

export default BallMap;
