import axiosClient from "..";

class LimboAPis {
  static create_bet(data) {
    return axiosClient.post("/api/v1/limbo/bets", data);
  }
  static my_bets() {
    return axiosClient.get("/api/v1/limbo/bets");
  }
  static get_client_seed() {
    return axiosClient.get("/api/v1/limbo/client_seed/new");
  }
  static get_new_seed(data, game) {
    return axiosClient.post(`/api/v1/${game}/generate_server_seed`, data);
  }
}
export default LimboAPis;
