import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Switch from "../Header/Wallet/Currencies/Switch/Switch";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: "#1e2024",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function MaxBetAlert({
  maxBetAlert,
  setMaxBetAlert,
  setDontShowAlert,
  dontShowAlert,
  performAction,
  setAutoBetInProgress,
  autoBetInProgress,
  inProgressBet,
  setInprogressBet,
}) {
  const handleClose = () => {
    setMaxBetAlert(false);
    if (autoBetInProgress) {
      setAutoBetInProgress(false);
    }
    if (inProgressBet) {
      setInprogressBet(false);
    }
  };
  const dontShowHandle = () => {
    setDontShowAlert(!dontShowAlert);
  };
  const handlePlay = () => {
    performAction();
    maxBetAlert(false);
  };
  return (
    <div>
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              textAlign: "left",
            }}
            className="mb-3"
          >
            <span className="secondary_text">
              The bet amount may exceed the potential maximum profit. Please
              confirm you're aware that you may not get the full amount when you
              win a higher payout.
            </span>
          </div>
          <div className="do-not-remind-container mb-3 my_row">
            <Switch
              switchText="Don't show this again!"
              activeStatus={dontShowAlert}
              onClickHandle={dontShowHandle}
            />
          </div>
          <div className="width my_row space-evenly">
            <button
              className="bold white primary-btn primary-btn-disable"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="bold white primary-btn primary-btn-bg"
              onClick={handlePlay}
            >
              Play
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
