import React, { useContext, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CasinoIcon from "@mui/icons-material/Casino";
import SellIcon from "@mui/icons-material/Sell";
import LanguageIcon from "@mui/icons-material/Language";
import { modalsControl } from "../../Contexts/ModalsControl";
import { useSelector } from "react-redux";
import ChatIcon from "@mui/icons-material/Chat";
import { useNavigate } from "react-router-dom";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import SpinSvg from "./Assets/SpinSvg";
import TresureSvg from "./Assets/TresureSvg";
import { useTranslation, Trans } from "react-i18next";

const SideBar = ({
  setsidebarBox,
  setLanguageSelector,
  handleReferEarn,
  sidebarBox,
  chatBox,
  setChatBox,
  handleSpinOpen,
}) => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const {
    setSignInOpen,
    setWalletOpen,
    setVipClub,
    setQuestModal,
    activeLiveSupport,
  } = useContext(modalsControl);
  const navigate = useNavigate();
  const handleSideBar = () => {
    setsidebarBox(!sidebarBox);
  };

  const languageSelection = () => {
    setsidebarBox(true);
    setLanguageSelector(true);
  };
  const handleChatBox = () => {
    setChatBox(!chatBox);
  };
  const casinoHandle = () => {
    navigate("/casino/crash");
  };
  const limboHandle = () => {
    navigate("/casino/limbo");
  };
  const plinkoHandle = () => {
    navigate("/casino/plinko");
  };
  const minesHandle = () => {
    navigate("/casino/mines");
  };
  const ClassicDiceHandle = () => {
    navigate("/casino/classic-dice");
  };
  const kenoHandle = () => {
    navigate("/casino/keno");
  };
  const hashDiceHandle = () => {
    navigate("/casino/hash-dice");
  };
  const promotionsHandle = () => {
    navigate("/referal-management/referal-dashboard");
  };
  const handleWalletOpen = () => {
    if (!currentUser) {
      setSignInOpen(true);
      return;
    }
    setWalletOpen(true);
  };

  const handleQuest = () => {
    setQuestModal(true);
  };
  const handleVip = () => {
    setVipClub(true);
  };
  const { t } = useTranslation();
  return (
    <div className="sidebar-main-container">
      <div className="sidebar-inner-container">
        <div className="sidebar-content-container">
          <div className="side-bar-open-icon-container width justified">
            <button
              className="text_color nav-item-text-mobile menu-items-on-mobile"
              style={{ width: "70px", height: "50px", background: "#2b2d34" }}
              onClick={handleSideBar}
            >
              <img
                src="/imgs/sidebar/sidebar-swipe-icon.svg"
                className="width-auto"
                alt=""
              />
              <div className="mobile-nav-item-name-container">
                <span className="hidden">{t("header.referral.menu")}</span>
              </div>
            </button>
          </div>
          <div className="side-bar-navigation-actions-container">
            <div className="side-bar-colored-navigation-actions justified column relative gap-4">
              <button
                onClick={handleSpinOpen}
                className="side-bar-colored-action-button tasks-button text_color menu-items-on-mobile"
              >
                <SpinSvg />
                <img src="/imgs/sidebar/infiniteSpin.webp" alt="" />
                <div className="mobile-nav-item-name-container nav-item-text-mobile additionl-mt-3">
                  <span>{t("header.referral.spin")}</span>
                </div>
              </button>
              <button
                className="rounded-wallet-on-mobile-nav white justified column"
                onClick={handleWalletOpen}
              >
                <div className="mob-nav-wallet-icon-container-outer">
                  <div className="mob-nav-wallet-icon-container justified primary-btn-bg">
                    <AccountBalanceWalletIcon />
                  </div>
                </div>
                <div className="mobile-nav-item-name-container mobile-nav-item-name-container-wallet-capsule nav-item-text-mobile">
                  <span>{t("header.wallet.wallet")} </span>
                </div>
              </button>
              <button
                className="side-bar-colored-action-button text_color menu-items-on-mobile"
                onClick={handleReferEarn}
              >
                <TresureSvg />
                <img src="/imgs/sidebar/refer.webp" alt="" />
                <div className="mobile-nav-item-name-container nav-item-text-mobile additionl-mt-3">
                  <span>{t("header.referral.ern")}</span>
                </div>
              </button>
              <button
                className="side-bar-colored-action-button text_color hide-on-900px"
                onClick={handleQuest}
              >
                <img src="/imgs/sidebar/quest.webp" alt="" />
              </button>
              <button
                className="side-bar-colored-action-button text_color hide-on-900px"
                onClick={handleVip}
              >
                <img src="/imgs/sidebar/vip.webp" alt="" />
              </button>
            </div>
            <div className="side-bar-navigation-actions justified column gap-4">
              <button
                className="side-bar-navigation-action-button casino"
                onClick={casinoHandle}
              >
                {/* <CasinoIcon /> */}
                <img src="/imgs/sidebar/game-logos/crash.webp" alt="" />
              </button>
              <button
                className="side-bar-navigation-action-button casino"
                onClick={limboHandle}
              >
                <img src="/imgs/sidebar/game-logos/limbo.webp" alt="" />
              </button>
              <button
                className="side-bar-navigation-action-button casino"
                onClick={plinkoHandle}
              >
                <img src="/imgs/sidebar/game-logos/plinko.webp" alt="" />
              </button>
              <button
                className="side-bar-navigation-action-button casino"
                onClick={minesHandle}
              >
                <img src="/imgs/sidebar/game-logos/mines.webp" alt="" />
              </button>
              <button
                className="side-bar-navigation-action-button casino"
                onClick={ClassicDiceHandle}
              >
                <img
                  style={{
                    maxWidth: "35px",
                  }}
                  src="/imgs/sidebar/game-logos/classic-dice.webp"
                  alt=""
                />
              </button>
              <button
                className="side-bar-navigation-action-button casino"
                onClick={kenoHandle}
              >
                <img
                  style={{
                    maxWidth: "35px",
                  }}
                  src="/imgs/sidebar/game-logos/keno.webp"
                  alt=""
                />
              </button>
              <button
                className="side-bar-navigation-action-button casino"
                onClick={hashDiceHandle}
              >
                <img
                  style={{
                    maxWidth: "35px",
                  }}
                  src="/imgs/sidebar/game-logos/hash-dice.webp"
                  alt=""
                />
              </button>
            </div>
            <div className="side-bar-navigation-bottom-actions justified column gap-4">
              <button
                className="side-bar-navigation-action-button promotions"
                onClick={promotionsHandle}
              >
                <img src="/imgs/sidebar/promotion.webp" alt="" />
              </button>
              <button
                className="side-bar-navigation-action-button language"
                onClick={languageSelection}
              >
                <img
                  src="/imgs/sidebar/language.webp"
                  alt=""
                  style={{ maxWidth: "26px" }}
                />
              </button>
              <button
                className="side-bar-navigation-action-button language"
                style={{ filter: "none", filter: "grayscale(0)", opacity: "1" }}
                onClick={activeLiveSupport}
              >
                <img
                  src="/imgs/sidebar/chat.webp"
                  alt=""
                  style={{ maxWidth: "25px" }}
                />
              </button>
            </div>
          </div>
          <div className="mobile-chatBox-button-container-main">
            <div className="side-bar-chat-icon-containerwidth justified">
              <button
                className="text_color nav-item-text-mobile menu-items-on-mobile "
                onClick={handleChatBox}
              >
                <ChatIcon />
                <div className="mobile-nav-item-name-container">
                  <span>{t("header.referral.chtroom")}</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
