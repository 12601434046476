import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { modalsControl } from "../../Contexts/ModalsControl";
import { useTranslation, Trans } from "react-i18next";
import Quest from "../../../api/Quest/quest";
import Countdown from "react-countdown";
import ShowComponent from "../ShowComponent/ShowComponent";
import { useNavigate } from "react-router-dom";
import UnderMaintenance from "../../Common/Undermaintenance";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 760,
  // height: "90vh",
  overflow: "auto",
};

export default function QuestModal() {
  const { questModal, setQuestModal } = useContext(modalsControl);
  const handleOpen = () => setQuestModal(true);
  const handleClose = () => setQuestModal(false);
  const [quests, setQuests] = useState({
    expiration_time: "00000",
    quest_tasks_running: [],
  });
  const [loadingQuests, setLoadingQuests] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();

  // useEffect(() => {
  //   Quest.get_all_quest()
  //     .then((res) => {
  //       setLoadingQuests(false);
  //       setQuests(res.data.data);
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  const playGame = (route) => {
    handleClose();
    navigate(`/casino/${route}`);
  };
  const avatarHandle = () => {};
  const emailHandle = () => {};
  const googleAuthHandle = () => {};
  const avaiableTasks = {
    W_PLINKO: playGame,
    W_MINES: playGame,
    W_LIMBO: playGame,
    W_CRASH: playGame,
    P_PLINKO: playGame,
    P_MINES: playGame,
    P_LIMBO: playGame,
    P_CRASH: playGame,
    B_1: playGame,
    C_1: avatarHandle,
    C_2: emailHandle,
    C_3: googleAuthHandle,
    C_4: playGame,
  };
  const questHandle = (type, route) => {
    avaiableTasks[type](route);
  };

  return (
    <div>
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* <ShowComponent condition={!loadingQuests}> */}
        <Box sx={style}>
          <UnderMaintenance
            p1="BCW.CLUB is about to introduce a daily quests."
            p2="Which will result in
            thrilling rewards. Don't miss out!"
            time=""
            type="soon"
            support={false}
          />
        </Box>
        {/* </ShowComponent> */}
      </Modal>
    </div>
  );
}
