import axiosClient from "..";

class ClassicDiceApis {
  static place_bet(data) {
    return axiosClient.post("/api/v1/dice/bets", data);
  }
  static diceBets() {
    return axiosClient.get("/api/v1/dice/bets");
  }
}
export default ClassicDiceApis;
